import { Grid, Paper, Button, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import { TextField, Select } from "final-form-material-ui";
import Swal from "sweetalert2";
import axios from "axios";

function CrearUsuario() {
  function redirige() {
    window.location.href = "/#/InfoUsuarios";
  }
  const onSubmit = async (values) => {
    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    await sleep(300);

    axios({
      auth: {
        username: "admin.icontec@icontec.org",
        password: "quAngEraMuSTerGerEDE",
      },
      method: "post",
      url: "https://middlewarebackprd.azurewebsites.net/api/v1/CreaUsuario",
      data: {
        nombre: values.nombre,
        identificacion: values.identificacion,
        correo: values.correo,
        contrasena: values.contrasena,
        ut: values.ut,
        cargo: values.cargo,
        tipoUsuario: values.tipoUsuario,
      },
    })
      .then((Response) => {
        console.log(Response.data);

        Swal.fire({
          title: Response.data,
          icon: "warning",
          text: "Message!",
          type: "success",
        }).then(redirige);
      })
      .catch((error) => {
        Swal.fire({
          title: error,
          icon: "error",
          text: "Revise los campos e intente de nuevo!",
          type: "error",
        }).then(redirige);
      });
  };
  const validate = (values) => {};
  const [setRol, setPostRol] = useState([]);

  return (
    <div>
      <div style={{ padding: 16, margin: "auto", maxWidth: 800 }}>
        <font color="">
          {" "}
          <h2>CREAR USUARIO</h2>{" "}
        </font>

        <Form
          onSubmit={onSubmit}
          initialValues={{}}
          validate={validate}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Paper style={{ padding: 16 }}>
                <Grid container alignItems="flex-start" spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      required
                      name="nombre"
                      component={TextField}
                      type="text"
                      label="Nombre"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      required
                      name="identificacion"
                      component={TextField}
                      type="text"
                      label="N° Identificación:"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      required
                      name="correo"
                      component={TextField}
                      type="email"
                      label="correo"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="contrasena"
                      component={TextField}
                      type="password"
                      label="Contraseña:"
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="tipoUsuario"
                      component={Select}
                      label="Seleccione el tipo de usuario"
                      formControlProps={{ fullWidth: true }}
                    >
                      <MenuItem value="1">Administrador</MenuItem>
                      <MenuItem value="2">Tesoreria</MenuItem>
                      <MenuItem value="3">Usuario Final</MenuItem>
                      <MenuItem value="5">Gestor</MenuItem>
                      <MenuItem value="6">Comercial</MenuItem>
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      required
                      name="cargo"
                      component={TextField}
                      type="text"
                      label="Cargo"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      required
                      name="ut"
                      component={TextField}
                      type="text"
                      label="Unidad tecnica"
                    />
                  </Grid>

                  <hr />
                  <Grid item style={{ marginTop: 16 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={submitting}
                      label="siguiente"
                    >
                      CREAR
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </form>
          )}
        />
      </div>
    </div>
  );
}

export default CrearUsuario;
