import { Grid, Paper, Button, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Form, Field } from "react-final-form";
import { TextField, Select } from "final-form-material-ui";
import Swal from "sweetalert2";
import axios from "axios";

function CreaContreebute() {
  function redirige() {
    window.location.href = "/#/CreaContreebute";
  }
  const onSubmit = async (values) => {
    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    await sleep(300);
    window.alert(JSON.stringify(values, 0, 2));
    const fechaOtorgamiento = new Date(
      values.fechaOtorgamiento
    ).toLocaleDateString("en-US");
    const fechaVencimiento = new Date(
      values.fechaVencimiento
    ).toLocaleDateString("en-US");

    axios({
      auth: {
        username: "admin.icontec@icontec.org",
        password: "quAngEraMuSTerGerEDE",
      },
      method: "post",
      url: "https://middlewarebackprd.azurewebsites.net/api/v1/CreaContreebute",
      data: {
        razonSocial: values.razonSocial,
        nit: values.nit,
        direccionSedePrincipal: values.direccionSedePrincipal,
        ciudad: values.ciudad,
        pais: values.pais,

        correoEmpresa: values.correoEmpresa,
        tipoEvaluacion: values.tipoEvaluacion,

        direccionamientoEstrategico: values.direccionamientoEstrategico,
        dimensionAmbiental: values.dimensionAmbiental,
        dimensionEconomica: values.dimensionEconomica,
        dimensionSocial: values.dimensionSocial,
        resultadoTotal: values.resultadoTotal,

        categoriaAlcanzada: values.categoriaAlcanzada,
        numeroCertificado: values.numeroCertificado,
        nombreAuditor: values.nombreAuditor,

        fechaOtorgamiento: fechaOtorgamiento,
        fechaVencimiento: fechaVencimiento,
        fechaAprobacion: values.fechaAprobacion,
        fechaActualizacion: values.fechaActualizacion,
        nombreImagenSello: values.nombreImagenSello,
        urlImagenSello: values.urlImagenSello,
      },
    })
      .then((Response) => {
        console.log(Response.data);
        Swal.fire({
          title: Response.data,
          icon: "success",
          type: "success",
        }).then((window.location.href = "/#/InfoContreebute"));
      })

      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: error,
          icon: "error",
          text: "Revise los campos e intente de nuevo!",
          type: "error",
        }).then(redirige);
      });
  };
  const validate = (values) => {
    const errors = {};
    if (!values.razonSocial) {
      errors.razonSocial = "Required";
    }

    if (!values.nit) {
      errors.nit = "Required";
    }

    if (!values.direccionSedePrincipal) {
      errors.direccionSedePrincipal = "Required";
    }
    if (!values.ciudad) {
      errors.ciudad = "Required";
    }
    if (!values.pais) {
      errors.pais = "Required";
    }
    if (!values.correoEmpresa) {
      errors.correoEmpresa = "Required";
    }
    if (!values.tipoEvaluacion) {
      errors.tipoEvaluacion = "Required";
    }
    if (!values.direccionamientoEstrategico) {
      errors.direccionamientoEstrategico = "Required";
    }
    if (!values.dimensionAmbiental) {
      errors.dimensionAmbiental = "Required";
    }
    if (!values.dimensionEconomica) {
      errors.dimensionEconomica = "Required";
    }
    if (!values.dimensionSocial) {
      errors.dimensionSocial = "Required";
    }
    if (!values.resultadoTotal) {
      errors.resultadoTotal = "Required";
    }
    if (!values.categoriaAlcanzada) {
      errors.categoriaAlcanzada = "Required";
    }
    if (!values.numeroCertificado) {
      errors.numeroCertificado = "Required";
    }
    if (!values.nombreAuditor) {
      errors.nombreAuditor = "Required";
    }
    if (!values.fechaOtorgamiento) {
      errors.fechaOtorgamiento = "Required";
    }
    if (!values.fechaVencimiento) {
      errors.fechaVencimiento = "Required";
    }
    if (!values.fechaAprobacion) {
      errors.fechaAprobacion = "Required";
    }
    if (!values.fechaActualizacion) {
      errors.fechaActualizacion = "Required";
    }
    if (!values.nombreImagenSello) {
      errors.nombreImagenSello = "Required";
    }
    if (!values.urlImagenSello) {
      errors.urlImagenSello = "Required";
    }

    console.log(errors);
    return errors;
  };
  const today = new Date().toISOString().split("T")[0];

  return (
    <div>
      <div style={{ padding: 16, margin: "auto", maxWidth: 800 }}>
        <font color="">
          <h2>CREAR CONTREEBUTE</h2>{" "}
        </font>
        <Form
          onSubmit={onSubmit}
          initialValues={{
            fechaVencimiento: today,
            fechaOtorgamiento: today,
            fechaAprobacion: today,
            fechaActualizacion: today,
          }}
          validate={validate}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Paper style={{ padding: 16 }}>
                <Grid container alignItems="flex-start" spacing={2}>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="razonSocial"
                      component={TextField}
                      type="text"
                      label="RazonSocial"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="nit"
                      component={TextField}
                      type="text"
                      label="NIT"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      required
                      name="direccionSedePrincipal"
                      component={TextField}
                      type="text"
                      label="DireccionSedePrincipal"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="ciudad"
                      component={TextField}
                      type="text"
                      label="Ciudad:"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="pais"
                      component={TextField}
                      type="text"
                      label="Pais:"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      required
                      name="correoEmpresa"
                      component={TextField}
                      type="email"
                      label="CorreoEmpresa:"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="tipoEvaluacion"
                      component={TextField}
                      type="text"
                      label="TipoEvaluacion:"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      required
                      name="direccionamientoEstrategico"
                      component={TextField}
                      type="number"
                      label="DireccionamientoEstrategico:"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="dimensionAmbiental"
                      component={TextField}
                      type="number"
                      label="DimensionAmbiental:"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="dimensionEconomica"
                      component={TextField}
                      type="number"
                      label="DimensionEconomica:"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="dimensionSocial"
                      component={TextField}
                      type="number"
                      label="DimensionSocial:"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="resultadoTotal"
                      component={TextField}
                      type="number"
                      label="ResultadoTotal:"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="categoriaAlcanzada"
                      component={TextField}
                      type="text"
                      label="CategoriaAlcanzada:"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="numeroCertificado"
                      component={TextField}
                      type="text"
                      label="NumeroCertificado:"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      required
                      name="nombreAuditor"
                      component={TextField}
                      type="text"
                      label="NombreAuditor:"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="fechaOtorgamiento"
                      component={TextField}
                      type="date"
                      label="FechaOtorgamiento:"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="fechaVencimiento"
                      component={TextField}
                      type="date"
                      label="FechaVencimiento:"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="fechaAprobacion"
                      component={TextField}
                      type="date"
                      label="FechaAprobacion:"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="fechaActualizacion"
                      component={TextField}
                      type="date"
                      label="FechaActualizacion:"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="nombreImagenSello"
                      component={TextField}
                      type="text"
                      label="NombreImagenSello:"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="urlImagenSello"
                      component={TextField}
                      type="text"
                      label="URLImagenSello:"
                    />
                  </Grid>

                  <hr />
                  <Grid item style={{ marginTop: 16 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={submitting}
                      label="siguiente"
                    >
                      CREAR
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </form>
          )}
        />
      </div>
    </div>
  );
}

export default CreaContreebute;
