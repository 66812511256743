import { AiFillFacebook } from "react-icons/ai";
import face from "../../img/facebook.svg";

import twii from "../../img/twitter.svg";
import youtube from "../../img/youtube.svg";
import link from "../../img/linkedin.svg";

const FooterIcontec = () => {
  return (
    <footer className="footer" id="footer">
      <section className="footer__container container">
        <div className="row">
          <div className="col-12 col-lg-3 text-lg-left text-center">
            <ul>
              {/* <li class="footer__item footer__title"><a href="" target=""
                  rel=""></a></li>*/}
              <li className="footer__item footer__title">
                <strong>
                  <a
                    href="https://www.elempleo.com/co/base-empresarial/icontec/1802"
                    target
                    rel
                    style={{ color: "#002E5D" }}
                  >
                    TRABAJE CON NOSOTROS
                  </a>
                </strong>
              </li>

              <li className="footer__item footer__title">
                <strong>
                  <a
                    href="https://admin.dataella.com/capturador/Icontec/PLATAFORMASGI/"
                    target
                    rel
                    style={{ color: "#002E5D" }}
                  >
                    Afíliese
                  </a>
                </strong>
              </li>
            </ul>
          </div>
          <div className="newsletter col-12 col-lg-3 text-lg-left text-center">
            <div className="newslatter__container">
              <ul>
                {/*<li class="footer__item footer__title"><a href="" target=""
                      rel=""></a></li>*/}
                <li className="footer__item footer__title">
                  <strong>
                    <a
                      href="https://www.icontec.org/noticias/"
                      target
                      rel
                      style={{ color: "#002E5D" }}
                    >
                      Noticias
                    </a>
                  </strong>
                </li>
                <li className="footer__item footer__title">
                  <strong>
                    <a
                      href="https://www.icontec.org/nuestras-oficinas/"
                      target
                      rel
                      style={{ color: "#002E5D" }}
                    >
                      Nuestras Oficinas
                    </a>
                  </strong>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-12 col-lg-3 d-flex d-lg-block align-items-center flex-column">
            <strong>
              <p className="footer__title" style={{ color: "#7C878E" }}>
                <strong>Seleccion de país</strong>
              </p>
            </strong>
            <select className="custom-select" id="countrySelector">
              <option
                value="col"
                selected
                data-imagesrc="https://www.huelladeconfianza.com/wp-content/uploads/2019/07/Colombia-1.svg"
                data-description
              >
                Colombia
              </option>{" "}
            </select>
          </div>
          <div className="col-12 col-lg-3 d-flex d-lg-block align-items-center flex-column">
            <p className="footer__title" style={{ color: "#7C878E" }}>
              <strong>Síguenos</strong>
            </p>
            <div className="col footer-social d-flex justify-content-lg-start justify-content-center  align-items-center social">
              <a
                className="social__link"
                href="https://www.facebook.com/ICONTEC.internacional"
              >
                <img
                  className=" ls-is-cached lazyloaded"
                  src={face}
                  data-src="https://www.huelladeconfianza.com/wp-content/themes/infinity-theme/img/facebook.svg "
                  style={{ width: "70%", height: "auto" }}
                />
              </a>
              <a className="social__link" href="https://twitter.com/ICONTEC">
                <noscript>
                  &lt;img
                  src="https://www.huelladeconfianza.com/wp-content/themes/infinity-theme/img/twitter.svg
                  " alt=""&gt;
                </noscript>
                <img
                  className=" ls-is-cached lazyloaded"
                  src={twii}
                  data-src="https://www.huelladeconfianza.com/wp-content/themes/infinity-theme/img/twitter.svg "
                  style={{ width: "70%", height: "auto" }}
                />
              </a>
              <a
                className="social__link"
                href="https://www.youtube.com/user/icontecinternacional"
              >
                <noscript>
                  &lt;img
                  src="https://www.huelladeconfianza.com/wp-content/themes/infinity-theme/img/youtube.svg
                  " alt=""&gt;
                </noscript>
                <img
                  className=" ls-is-cached lazyloaded"
                  src={youtube}
                  data-src="https://www.huelladeconfianza.com/wp-content/themes/infinity-theme/img/youtube.svg "
                  style={{ width: "70%", height: "auto" }}
                />
              </a>
              <a
                className="social__link"
                href="https://www.linkedin.com/company/icontec-international"
              >
                <noscript>
                  &lt;img
                  src="https://www.huelladeconfianza.com/wp-content/themes/infinity-theme/img/linkedin.svg
                  " alt=""&gt;
                </noscript>
                <img
                  className=" ls-is-cached lazyloaded"
                  src={link}
                  data-src="https://www.huelladeconfianza.com/wp-content/themes/infinity-theme/img/linkedin.svg "
                  style={{ width: "70%", height: "auto" }}
                />
              </a>
            </div>
          </div>
        </div>
        <div className="row mt-4 flex-column flex-lg-row ">
          {/*<div
      class="col-12 col-lg-3 d-flex flex-column justify-content-lg-start justify-content-center align-items-center">
      <a href="">
          <p><small></small></p>
          <img src="" alt="">
      </a>
  </div>*/}
          <div className="col-12 col-lg-3 d-flex flex-column justify-content-lg-start justify-content-center align-items-center">
            <a href="http://sical.gov.co">
              <p>
                <small style={{ color: "#0085CA" }}>
                  <strong>Subsistema Nacional de Calidad </strong>
                </small>
              </p>
              <noscript>
                &lt;img
                src="https://www.icontec.org/wp-content/uploads/2019/08/sical-1-2.png"
                alt=""&gt;
              </noscript>
              <img
                className="imagen-foot"
                src="https://www.icontec.org/wp-content/uploads/2019/08/sical-1-2.png"
                style={{ width: "100%", height: "auto" }}
              />
            </a>
          </div>
          <div className="col-12 col-lg-3 d-flex flex-column justify-content-lg-start justify-content-center align-items-center">
            <a href>
              <p>
                <small style={{ color: "#0085CA" }}>
                  {" "}
                  <strong>Miembro de la red</strong>
                </small>
              </p>
              <noscript>
                &lt;img
                src="https://www.icontec.org/wp-content/uploads/2019/08/IQNet-Ltd_colour_40.png"
                alt=""&gt;
              </noscript>
              <img
                className="imagen-foot"
                src="https://www.icontec.org/wp-content/uploads/2019/08/IQNet-Ltd_colour_40.png"
                data-src="https://www.icontec.org/wp-content/uploads/2019/08/IQNet-Ltd_colour_40.png"
                style={{ width: "100%", height: "auto" }}
              />
            </a>
          </div>
          <div className="col-12 col-lg-3 d-flex flex-column justify-content-lg-start justify-content-center align-items-center">
            <a href="http://masfamilia.org">
              <p>
                <small style={{ color: "#0085CA" }}>
                  <strong> Empresa Familiarmente Responsable</strong>
                </small>
              </p>
              <noscript>
                &lt;img
                src="https://www.icontec.org/wp-content/uploads/2019/08/efr-3-1.png"
                alt=""&gt;
              </noscript>
              <img
                className=" ls-is-cached lazyloaded"
                src="https://www.icontec.org/wp-content/uploads/2019/08/efr-3-1.png"
                data-src="https://www.icontec.org/wp-content/uploads/2019/08/efr-3-1.png"
                style={{ width: "100%", height: "auto" }}
              />
            </a>
          </div>
          <div className="col-12 col-lg-3 d-flex flex-column justify-content-lg-start justify-content-center align-items-center">
            <a href="https://icontec.pagosinteligentes.com/Cartera/Ingreso.aspx?AciD=23331">
              <p>
                <small style={{ color: "#0085CA" }}>
                  <strong>Paque aquí</strong>
                </small>
              </p>
              <noscript>
                &lt;img
                src="https://www.icontec.org/wp-content/uploads/2020/05/icono_pago_en_linea_Mesa_de_trabajo.png"
                alt=""&gt;
              </noscript>
              <img
                className=" ls-is-cached lazyloaded"
                src="https://www.icontec.org/wp-content/uploads/2020/05/icono_pago_en_linea_Mesa_de_trabajo.png"
                data-src="https://www.icontec.org/wp-content/uploads/2020/05/icono_pago_en_linea_Mesa_de_trabajo.png"
                style={{ width: "100%", height: "auto" }}
              />
            </a>
          </div>
        </div>
      </section>
      <section className="footer-bottom">
        <div className="footer-bottom__container container border-top">
          <div className="row">
            <div className="col-12 col-lg-6 footer-copy d-flex justify-content-start">
              <p className="footer__text--small" style={{ color: "#7C878E" }}>
                <strong> 2024 ICONTEC Todos los derechos reservados</strong>
              </p>
            </div>
            <div
              className="col footer-social d-flex justify-content-end social second-color font-weight-bold "
              style={{ color: "#0085CA" }}
            >
              <a href>Términos y condiciones</a>
            </div>
          </div>
        </div>
      </section>
    </footer>
  );
};
export default FooterIcontec;
