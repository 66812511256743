import { Helmet } from "react-helmet-async";
// @mui
import { styled } from "@mui/material/styles";
import {
  Link,
  Container,
  Typography,
  Divider,
  Stack,
  Button,
} from "@mui/material";
// hooks
import useResponsive from "../hooks/useResponsive";
// components
import Logo from "../components/logo";
import Iconify from "../components/iconify";
// sections
import { LoginForm } from "../sections/auth/login";

// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const StyledSection = styled("div")(({ theme }) => ({
  width: "100%",
  maxWidth: 480,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const postData = (e) => {
    window.location.href =
      "https://middlewarebackprd.azurewebsites.net/api/v1/AuthAzure";
  };
  const consulta = (e) => {
    window.location.href = "/";
  };
  const mdUp = useResponsive("up", "md");

  return (
    <>
      <Helmet>
        <title> Login </title>
      </Helmet>

      <StyledRoot>
        {mdUp && (
          <StyledSection>
            <Typography
              variant="h3"
              sx={{ px: 5, mt: 10, mb: 5 }}
              color={"#0085CA"}
            >
              Bienvenido De Nuevo
            </Typography>
            <img
              src="https://us.123rf.com/450wm/merfin/merfin1606/merfin160600211/57553513-car%C3%A1cter-vectorial-detallada-oficinista-empleado-de-oficina-de-negocios-en-acciones-la-gente-de.jpg?ver=6"
              alt="login"
            />
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent>
            <center>
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/8d/Logo_ICONTEC_Horizontal.svg/2560px-Logo_ICONTEC_Horizontal.svg.png"
                alt="login"
                width={300}
              />
            </center>
            <Typography variant="h4" gutterBottom>
              <hr />
            </Typography>
            <Divider sx={{ my: 3 }}>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                Inicio de sesión
              </Typography>
            </Divider>

            <Stack direction="row" spacing={2}>
              <Button
                onClick={postData}
                fullWidth
                size="large"
                color="inherit"
                variant="outlined"
              >
                <Iconify
                  icon="mdi:microsoft"
                  color="#DF3E30"
                  width={22}
                  height={22}
                />
              </Button>
            </Stack>
            <Divider sx={{ my: 3 }}>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                Consulta Pública
              </Typography>
            </Divider>
            <Stack direction="row" spacing={2}>
              <Button
                onClick={consulta}
                fullWidth
                size="large"
                color="inherit"
                variant="outlined"
              >
                <Iconify
                  icon="il:search"
                  color="#DF3E30"
                  width={22}
                  height={22}
                />
              </Button>
            </Stack>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
