import "../css/Header.css";
import img from "../img/LOGO-Icontec-2019_hor_pos.png";

function HuellaNav() {
  return (
    <section className="section-menu">
      <div className="menu-d d-lg-flex d-none bg-main shadow-sm">
        <div className="circle-container">
          <div className="circle" />
        </div>
        <div className="container">
          {/* init */}
          <div className="row">
            <div className="Logo col-md-2 d-flex justify-content-center align-items-center bg-white">
              <a
                href="https://www.huelladeconfianza.com/"
                className="custom-logo-link"
                rel="home"
                aria-current="page"
              >
                <img
                  src={img}
                  data-src={img}
                  className="custom-logo ls-is-cached lazyloaded"
                  alt="Huella de Confianza – Icontec"
                  height={57}
                  width={191}
                />
              </a>{" "}
            </div>
            <div className="col-md-10">
              <div className="row">
                <div className="col-12">
                  {/* Init */}
                  <div className="d-flex justify-content-end align-items-end">
                    <nav className="top-menu">
                      <ul
                        id="menu-top-menu"
                        className="top-menu d-none d-md-flex"
                      >
                        <li
                          id="menu-item-325"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-325"
                        >
                          <a href="https://tienda.icontec.org/?v=42983b05e2f2">
                            Tienda
                          </a>
                        </li>
                        <li
                          id="menu-item-326"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-326"
                          color="white"
                        >
                          <a href="https://ecollection.icontec.org/">
                            e-collection
                          </a>
                        </li>
                        <li
                          id="menu-item-8289"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-8289"
                        >
                          <a href="https://afiliados.icontec.org/">Afiliese </a>
                        </li>
                        <li
                          id="menu-item-9377"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-9377"
                        >
                          <a href="https://ecollection.icontec.org/lnkext.aspx?Q=357E7AF7B64634F4">
                            Buscador de normas
                          </a>
                        </li>
                        <li
                          id="menu-item-6645"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-6645"
                        >
                          <a href="https://icono.icontec.org/lms/">Icono</a>
                        </li>
                      </ul>
                    </nav>{" "}
                  </div>
                  {/* end */}
                </div>
                <div className="col-9">
                  {/* ini col-8 */}
                  <nav className="main-menu-container">
                    <ul
                      id="menu-main-menu-colombia"
                      className="main-menu d-none d-md-flex mt-1"
                    >
                      <li
                        className="nav-item dropdown menu-item"
                        id="menu-item-16"
                      >
                        <a
                          className="nav-link dropdown-toggle"
                          href="#"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Nuestra Compañia
                        </a>
                        <ul
                          className="dropdown-menu"
                          style={{ backgroundColor: "white" }}
                        >
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://www.icontec.org/quienes-somos/"
                              style={{ color: "#007bff" }}
                            >
                              Quiénes Somos
                            </a>
                          </li>
                          <hr />
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://www.icontec.org/nuestro-equipo/"
                              style={{ color: "#007bff" }}
                            >
                              Nuestro Equipo
                            </a>
                          </li>
                          <hr />
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://www.icontec.org/nuestra-historia/"
                              style={{ color: "#007bff" }}
                            >
                              Nuestra Historia
                            </a>
                          </li>
                          <hr />
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://www.icontec.org/beneficios-de-afiliacion/"
                              style={{ color: "#007bff" }}
                            >
                              Beneficios de afiliación
                            </a>
                          </li>
                          <hr />
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://www.icontec.org/documentos-corporativos/"
                              style={{ color: "#007bff" }}
                            >
                              Documentos corporativos
                            </a>
                          </li>
                          <hr />
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://www.icontec.org/documentos-servicios-icontec/"
                              style={{ color: "#007bff" }}
                            >
                              Documentos Servicios
                            </a>
                          </li>
                          <hr />
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://www.icontec.org/regimen-tributario-especial/"
                              style={{ color: "#007bff" }}
                            >
                              Regimen Tributario Especial
                            </a>
                          </li>
                          <hr />
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://www.elempleo.com/co/empleos-empresas"
                              style={{ color: "#007bff" }}
                            >
                              Trabaje con nosotros
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li
                        className="nav-item dropdown menu-item"
                        id="menu-item-16"
                      >
                        <a
                          className="nav-link dropdown-toggle"
                          href="#"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Sectores
                        </a>
                        <ul
                          className="dropdown-menu"
                          style={{ backgroundColor: "white" }}
                        >
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://www.icontec.org/agroalimentario/"
                              style={{ color: "#007bff" }}
                            >
                              Agroalimentario
                            </a>
                          </li>
                          <hr />
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://www.icontec.org/industria/"
                              style={{ color: "#007bff" }}
                            >
                              Manufactura y produccion industrial
                            </a>
                          </li>
                          <hr />
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://www.icontec.org/petroleo-y-gas/"
                              style={{ color: "#007bff" }}
                            >
                              Energético, petroleo y gas
                            </a>
                          </li>
                          <hr />
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://www.icontec.org/construccion-e-ingenieria/"
                              style={{ color: "#007bff" }}
                            >
                              Construcción e Inmobiliario
                            </a>
                          </li>
                          <hr />
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://www.icontec.org/educacion/"
                              style={{ color: "#007bff" }}
                            >
                              Sector Educativo
                            </a>
                          </li>
                          <hr />
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://www.icontec.org/logistica-y-transporte/"
                              style={{ color: "#007bff" }}
                            >
                              logistica y transporte
                            </a>
                          </li>
                          <hr />
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://www.icontec.org/salud/"
                              style={{ color: "#007bff" }}
                            >
                              Salud
                            </a>
                          </li>
                          <hr />
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://www.icontec.org/turismo-y-gastronomia/"
                              style={{ color: "#007bff" }}
                            >
                              Turismo y gastronomia
                            </a>
                          </li>
                          <hr />
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://www.icontec.org/administracion-publica/"
                              style={{ color: "#007bff" }}
                            >
                              Administración publica
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li
                        className="nav-item dropdown menu-item"
                        id="menu-item-16"
                      >
                        <a
                          className="nav-link dropdown-toggle"
                          href="#"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Servicios
                        </a>
                        <ul
                          className="dropdown-menu"
                          style={{ backgroundColor: "white" }}
                        >
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://www.icontec.org/normalizacion/"
                              style={{ color: "#007bff" }}
                            >
                              Normalización
                            </a>
                          </li>
                          <hr />
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://www.icontec.org/servicio-educacion/"
                              style={{ color: "#007bff" }}
                            >
                              Educación
                            </a>
                          </li>
                          <hr />
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://www.icontec.org/evaluacion-de-la-conformidad/"
                              style={{ color: "#007bff" }}
                            >
                              Evaluación de la conformidad
                            </a>
                          </li>
                          <hr />
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://www.icontec.org/cooperacion/"
                              style={{ color: "#007bff" }}
                            >
                              Cooperación y Proyectos Especiales
                            </a>
                          </li>
                        </ul>
                      </li>

                      <li className="nav-item dropdown menu-item" id="">
                        <a
                          className="nav-link"
                          href="https://econecta.icontec.org/"
                          data-bs-toggle="dropdown"
                          aria-expanded="true"
                        >
                          Consulta Pública
                        </a>
                      </li>
                      <li className="nav-item dropdown menu-item" id="">
                        <a
                          className="nav-link"
                          href="https://www.icontec.org/contactenos/"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Contáctenos
                        </a>
                      </li>
                    </ul>
                  </nav>

                  {/* end col-8 */}
                </div>
                <div className="col-2">
                  <div className="btn-clientes">
                    <a
                      href="https://clientes.huelladeconfianza.com"
                      target="_blank"
                    >
                      Ingreso a clientes
                    </a>
                  </div>
                </div>
                <div className="col-2">
                  <div className="btn-pagos">
                    <a
                      href="https://icontec.pagosinteligentes.com/Cartera/Ingreso.aspx?AciD=23331"
                      target="_blank"
                    >
                      Pagos en linea
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* end */}
        </div>
      </div>
      <div className="d-none d-lg-block">
        <div className="search__absolute" />
        {/* </div> */}
        <div className="collapse w-100 collapse-search" id="collapse-search">
          <form
            role="search"
            method="get"
            className="search d-flex w-100"
            action="https://www.huelladeconfianza.com/"
          >
            <div className="row w-100 m-0 justify-content-center align-items-center container">
              <input
                className="search__input form-control m-0 col-12 col-lg-12"
                type="search"
                id="s"
                placeholder="¿Qué estás buscando?"
                defaultValue
                name="s"
                title="¿Qué estás buscando?"
              />
              <span />
              <input
                className="btn search__buttom btn-primary col-lg-2 d-none d-lg-block"
                type="submit"
                defaultValue="Buscar"
              />
            </div>
          </form>
        </div>
      </div>
      <div className="menu-mobile d-lg-none">
        <div className="menu-m py-3">
          <div className="container">
            <div className="circle-container">
              <div className="circle" />
            </div>
            <div className="row">
              <div className="col-6">
                <div className="Logo col-md-2 d-flex justify-content-center align-items-center bg-white">
                  <a
                    href="https://www.huelladeconfianza.com/"
                    className="custom-logo-link"
                    rel="home"
                    aria-current="page"
                  >
                    <noscript>
                      &lt;img
                      src="https://www.huelladeconfianza.com/wp-content/uploads/2019/07/logotipo.svg"
                      class="custom-logo" alt="Huella de Confianza &amp;#8211;
                      Icontec" height='57' width='191' /&gt;
                    </noscript>
                    <img
                      src="data:image/svg+xml,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%20191%2057%22%3E%3C/svg%3E"
                      data-src="https://www.huelladeconfianza.com/wp-content/uploads/2019/07/logotipo.svg"
                      className="lazyload custom-logo"
                      alt="Huella de Confianza – Icontec"
                      height={57}
                      width={191}
                    />
                  </a>{" "}
                </div>
              </div>
              <div className="col-6 d-flex justify-content-between align-items-center pl-5">
                <a
                  className="pl-4"
                  data-toggle="collapse"
                  href="#collapse-search"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapse-search"
                >
                  <noscript>
                    &lt;img
                    src="https://www.huelladeconfianza.com/wp-content/themes/infinity-theme/img/search.svg"&gt;
                  </noscript>
                  <img
                    className="lazyload"
                    src="data:image/svg+xml,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%20210%20140%22%3E%3C/svg%3E"
                    data-src="https://www.huelladeconfianza.com/wp-content/themes/infinity-theme/img/search.svg"
                  />
                </a>
                <a
                  className
                  data-toggle="collapse"
                  href="#collapseExample"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  <noscript>
                    &lt;img
                    src="https://www.huelladeconfianza.com/wp-content/themes/infinity-theme/img/menu.svg"&gt;
                  </noscript>
                  <img
                    className="lazyload"
                    src="data:image/svg+xml,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%20210%20140%22%3E%3C/svg%3E"
                    data-src="https://www.huelladeconfianza.com/wp-content/themes/infinity-theme/img/menu.svg"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
        <div className="collapse w-100 collapse-search" id="collapse-search">
          <form
            role="search"
            method="get"
            className="search d-flex w-100"
            action="https://www.huelladeconfianza.com/"
          >
            <div className="row w-100 m-auto justify-content-center container">
              <input
                className="search__input form-control m-0 col-12 col-lg-12"
                type="search"
                id="s"
                placeholder="¿Qué estás buscando?"
                defaultValue
                name="s"
                title="¿Qué estás buscando?"
              />
              <span />
              <input
                className="btn search__buttom btn-primary col-lg-2 d-none d-lg-block"
                type="submit"
                defaultValue="Buscar"
              />
            </div>
          </form>
        </div>
        <div className="menu-m-content collapse" id="collapseExample">
          <div className>
            <div className="row">
              <div className="col-12">
                {/* ini col-8 */}
                <nav className="main-menu-container">
                  <ul
                    id="menu-main-menu-colombia-1"
                    className="main-menu d-md-flex"
                  >
                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-16">
                      <a href="#">Nuestra compañia</a>
                      <ul className="sub-menu">
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-11472">
                          <a href="https://www.icontec.org/quienes-somos/">
                            Quiénes somos
                          </a>
                        </li>
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-11473">
                          <a href="https://www.icontec.org/nuestro-equipo/">
                            Nuestro Equipo
                          </a>
                        </li>
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-11474">
                          <a href="https://www.icontec.org/nuestra-historia/">
                            Nuestra Historia
                          </a>
                        </li>
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-11475">
                          <a href="https://www.icontec.org/beneficios-de-afiliacion/">
                            Beneficios de afiliación
                          </a>
                        </li>
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-11476">
                          <a href="https://www.icontec.org/documentos-corporativos/">
                            Documentos corporativos
                          </a>
                        </li>
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-11477">
                          <a href="https://www.icontec.org/%e2%80%8bdocumentos-servicios-icontec/">
                            Documentos servicios
                          </a>
                        </li>
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-11478">
                          <a href="https://www.icontec.org/regimen-tributario-especial/">
                            Regímen Tributario Especial
                          </a>
                        </li>
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-9920">
                          <a href="https://www.elempleo.com/colombia/files/basesempresariales/icontec/">
                            Trabaje con nosotros
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-17">
                      <a href="#">Sectores</a>
                      <ul className="sub-menu">
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-11479">
                          <a href="https://www.icontec.org/agroalimentario/">
                            Agroalimentario
                          </a>
                        </li>
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-11480">
                          <a href="https://www.icontec.org/manufactura-y-produccion-industrial/">
                            Manufactura y Producción Industrial
                          </a>
                        </li>
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-11481">
                          <a href="https://www.icontec.org/energetico-petroleo-y-gas/">
                            Energético, Petróleo y Gas
                          </a>
                        </li>
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-11482">
                          <a href="https://www.icontec.org/construccion-e-inmobiliario/">
                            Construcción e Inmobiliario
                          </a>
                        </li>
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-11483">
                          <a href="https://www.icontec.org/educacion/">
                            Sector Educación
                          </a>
                        </li>
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-11484">
                          <a href="https://www.icontec.org/logistica-y-transporte/">
                            Logística y Transporte
                          </a>
                        </li>
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-11485">
                          <a href="https://www.icontec.org/salud/">Salud</a>
                        </li>
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-11486">
                          <a href="https://www.icontec.org/turismo-y-gastronomia/">
                            Turismo y Gastronomía
                          </a>
                        </li>
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-11487">
                          <a href="https://www.icontec.org/administracion-publica/">
                            Administración Pública
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-18">
                      <a href="#">Servicios</a>
                      <ul className="sub-menu">
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-11488">
                          <a href="https://www.icontec.org/normalizacion/">
                            Normalización
                          </a>
                        </li>
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-11489">
                          <a href="https://www.icontec.org/servicio-educacion/">
                            Educación
                          </a>
                        </li>
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-11490">
                          <a href="https://www.icontec.org/evaluacion-de-la-conformidad/">
                            Evaluación de la Conformidad
                          </a>
                        </li>
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-11491">
                          <a href="https://www.icontec.org/cooperacion/">
                            Cooperación y Proyectos Especiales
                          </a>
                        </li>
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-11492">
                          <a href="https://www.icontec.org/laboratorios/">
                            Laboratorios
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-10277">
                      <a href="https://econecta.icontec.org">
                        Consulta Pública
                      </a>
                    </li>
                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-11493">
                      <a href="https://www.icontec.org/contactenos/">
                        Contáctenos
                      </a>
                    </li>
                  </ul>
                </nav>
                {/* end col-8 */}
              </div>
              <div className="col-12">
                <nav className="buttom-menu-container">
                  <ul id="menu-top-menu-1" className="top-menu">
                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-325">
                      <a href="https://tienda.icontec.org/?v=42983b05e2f2">
                        Tienda
                      </a>
                    </li>
                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-326">
                      <a href="https://ecollection.icontec.org/">
                        e-collection
                      </a>
                    </li>
                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-8289">
                      <a href="https://afiliados.icontec.org/">Afiliese</a>
                    </li>
                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-9377">
                      <a href="https://ecollection.icontec.org/lnkext.aspx?Q=357E7AF7B64634F4">
                        Buscador de normas
                      </a>
                    </li>
                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-6645">
                      <a href="https://icono.icontec.org/lms/">Icono</a>
                    </li>
                  </ul>
                </nav>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HuellaNav;
