import { Grid, Paper, Button, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Form, Field } from "react-final-form";
import { TextField, Select } from "final-form-material-ui";
import Swal from "sweetalert2";
import axios from "axios";

function CrearCoordenada() {
  function redirige() {
    window.location.href = "/#/InfoCoordenada";
  }
  const onSubmit = async (values) => {
    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    await sleep(300);

    axios({
      auth: {
        username: "admin.icontec@icontec.org",
        password: "quAngEraMuSTerGerEDE",
      },
      method: "post",
      url: "https://middlewarebackprd.azurewebsites.net/api/v1/CreaPlantillasParametros",
      data: {
        llx: values.llx,
        lly: values.lly,
        urx: values.urx,
        ury: values.ury,
        leading: values.leading,
        aligment: "0",
        modulo: countryid,
        campo: stateid,
        tamano: values.tamano,
      },
    })
      .then((Response) => {
        Swal.fire({
          title: Response.data,
          icon: "success",
          type: "success",
        }).then();
      })

      .catch((error) => {
        Swal.fire({
          title: error,
          icon: "error",
          text: "Revise los campos e intente de nuevo!",
          type: "error",
        }).then(redirige);
      });
  };
  const validate = (values) => {
    const errors = {};
    if (!values.llx) {
      errors.llx = "Required";
    }

    if (!values.lly) {
      errors.lly = "Required";
    }

    if (!values.urx) {
      errors.urx = "Required";
    }
    if (!values.ury) {
      errors.ury = "Required";
    }
    if (!values.leading) {
      errors.leading = "Required";
    }

    if (!values.tamano) {
      errors.tamano = "Required";
    }

    console.log(errors);
    return errors;
  };

  const [country, setCountry] = useState([]);
  const [countryid, setCountryid] = useState("");
  const [st, setSt] = useState([]);
  const [stateid, setStateid] = useState("");

  useEffect(() => {
    const getcountry = async () => {
      try {
        // Define your username and password
        const username = "admin.icontec@icontec.org";
        const password = "quAngEraMuSTerGerEDE";

        // Create a Basic Authentication header by encoding the credentials
        const basicAuth = "Basic " + btoa(username + ":" + password);

        const headers = {
          Authorization: basicAuth,
          "Content-Type": "application/json", // You can adjust this based on your API's requirements
        };

        const requestOptions = {
          method: "GET",
          headers: headers,
        };

        const rescountry = await fetch(
          "https://middlewarebackprd.azurewebsites.net/api/v1/ConsultaModulos",
          requestOptions
        );

        if (rescountry.ok) {
          const rescon = await rescountry.json();
          setCountry(rescon);
        } else {
          console.error("Error fetching data:", rescountry.status);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    getcountry();
  }, []);

  const handlecountry = (event) => {
    const getcountryid = event.target.value;
    setCountryid(getcountryid);
  };

  useEffect(() => {
    if (countryid !== "") {
      const getstate = async () => {
        try {
          // Define your username and password
          const username = "admin.icontec@icontec.org";
          const password = "quAngEraMuSTerGerEDE";

          // Create a Basic Authentication header by encoding the credentials
          const basicAuth = "Basic " + btoa(username + ":" + password);

          const headers = {
            Authorization: basicAuth,
            "Content-Type": "application/json", // You can adjust this based on your API's requirements
          };

          const requestOptions = {
            method: "GET",
            headers: headers,
          };

          const resstate = await fetch(
            "https://middlewarebackprd.azurewebsites.net/api/v1/ObtenerCampos/" +
              countryid,
            requestOptions
          );

          if (resstate.ok) {
            const resst = await resstate.json();
            setSt(resst);
          } else {
            console.error("Error fetching data:", resstate.status);
          }
        } catch (error) {
          console.error("Error:", error);
        }
      };

      getstate();
    }
  }, [countryid]);

  const handlestate = (event) => {
    const getstateid = event.target.value;
    setStateid(getstateid);
  };
  return (
    <div>
      <div style={{ padding: 16, margin: "auto", maxWidth: 800 }}>
        <font color="">
          <h2>CREAR NUEVO CAMPO INFORME</h2>
        </font>
        <Form
          onSubmit={onSubmit}
          initialValues={{}}
          validate={validate}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Paper style={{ padding: 16 }}>
                <Grid container alignItems="flex-start" spacing={2}>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="llx"
                      component={TextField}
                      type="number"
                      label="llx"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="lly"
                      component={TextField}
                      type="number"
                      label="eje y"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="urx"
                      component={TextField}
                      type="number"
                      label="eje x"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="ury"
                      component={TextField}
                      type="number"
                      label="ury"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="leading"
                      component={TextField}
                      type="number"
                      label="interlineado"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <select
                      className="btn btn-secondary btn-user btn-block text-left"
                      onChange={(e) => handlecountry(e)}
                    >
                      <option value="0">Seleccione el modulo </option>
                      {country.map((getcon, index) => (
                        <option value={getcon.modulo}>{getcon.modulo} </option>
                      ))}
                    </select>
                  </Grid>
                  <Grid item xs={6}>
                    <select
                      className="btn btn-secondary btn-user btn-block text-left"
                      onChange={(e) => handlestate(e)}
                    >
                      <option value="0">Seleccione </option>
                      {st.map((getst, index) => (
                        <option key={index} value={getst.nombreCampo}>
                          {getst.nombreCampo}{" "}
                        </option>
                      ))}
                    </select>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="tamano"
                      component={TextField}
                      type="number"
                      label="tamaño"
                    />
                  </Grid>

                  <hr />
                  <Grid item style={{ marginTop: 16 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={submitting}
                      label="siguiente"
                    >
                      CREAR
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </form>
          )}
        />
      </div>
    </div>
  );
}

export default CrearCoordenada;
