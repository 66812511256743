import React, { useCallback, useEffect, useMemo, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import { Box, IconButton, MenuItem, Tooltip, Typography } from "@mui/material";
import {
  Check,
  Delete,
  Edit,
  Send,
  SendAndArchive,
  SendAndArchiveOutlined,
  SendAndArchiveSharp,
  SendRounded,
  Update,
} from "@mui/icons-material";
import axios from "axios";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";
import { MRT_Localization_ES } from "material-react-table/locales/es";

const InfoTesoreria = () => {
  const [postCertificado, setPostCertificado] = useState([]);

  const cookie = new Cookies();
  const postDatas = (e) => {
    window.location.href = "/CrearCertificado";
  };
  const [list1, setList1] = useState([]);

  const urlendpoint =
    "https://middlewarebackprd.azurewebsites.net/api/v1/ObtienePendientes/Todo";
  useEffect(() => {
    axios({
      auth: {
        username: "admin.icontec@icontec.org",
        password: "quAngEraMuSTerGerEDE",
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      method: "get",

      url: urlendpoint,
    })
      .then((Response) => {
        setList1(Response.data);
        console.log(Response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [setList1]);

  const [tableData, setTableData] = useState(() => list1);
  const [validationErrors, setValidationErrors] = useState({});

  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    if (!Object.keys(validationErrors).length) {
      tableData[row.index] = values;
      setTableData([...tableData]);
      exitEditingMode();
    }
  };

  const EmitirValid = useCallback((row) => {
    Swal.fire({
      title: "Generando Certificado...",
      text: "Espere un momento.",
      showConfirmButton: false,
      allowOutsideClick: false,
    });
    axios({
      auth: {
        username: "admin.icontec@icontec.org",
        password: "quAngEraMuSTerGerEDE",
      },
      method: "post",
      url: "https://middlewarebackprd.azurewebsites.net/api/v1/CreaCertificadoSend",

      data: {
        id: row.getValue("id"),
        id_certificado: row.getValue("id_certificado"),
        modulo: row.getValue("modulo"),
        numero_certificado: row.getValue("numero_certificado"),
        correo: row.getValue("correo"),
        envio_correo: "1",
        documento: row.getValue("documento"),
        nombre: row.getValue("nombre"),
        asistio: row.getValue("asistio"),
        certificado: row.getValue("certificado"),
        certidicado_ingles: row.getValue("certificado_ingles"),
        alcance: row.getValue("alcance"),
        alcance_ingles: row.getValue("alcance_ingles"),
        intensidad: row.getValue("intensidad"),
        listado_cursos: row.getValue("listado_cursos"),
        regional: row.getValue("regional"),
        ciudad: row.getValue("ciudad"),
        pais: row.getValue("pais"),
        fecha_certificado: row.getValue("fecha_certificado"),
        fecha_aprobacion: row.getValue("fecha_aprobacion"),
        fecha_ultima_modificaion: row.getValue("fecha_ultima_modificacion"),
        fecha_renovacion: row.getValue("fecha_renovacion"),
        fecha_vencimiento: row.getValue("fecha_vencimiento"),
        fecha_impresion: row.getValue("fecha_impresion"),
        direccion: row.getValue("direccion"),
        dato_1: row.getValue("dato_1"),
        dato_2: row.getValue("dato_2"),
        dato_3: row.getValue("dato_3"),
        dato_4: row.getValue("dato_4"),
        dato_5: row.getValue("dato_5"),
        dato_6: row.getValue("dato_6"),
        dato_7: row.getValue("dato_7"),
        dato_8: row.getValue("dato_8"),
        dato_9: row.getValue("dato_9"),
        dato_10: row.getValue("dato_10"),
        dato_11: row.getValue("dato_11"),
        dato_12: row.getValue("dato_12"),
        dato_13: row.getValue("dato_13"),
        dato_14: row.getValue("dato_14"),
        dato_15: row.getValue("dato_15"),
        estado: "",
        emitido: "Interno",
        dato_16: row.getValue("dato_16"),
        dato_17: row.getValue("dato_17"),
        dato_18: row.getValue("dato_18"),
        dato_19: row.getValue("dato_19"),
        dato_20: "Aprobado",
      },
    })
      .then((Response) => {
        console.log(Response.data);
        Swal.fire({
          title: "Generado",

          icon: "success",
          type: "success",
          confirmButtonText: "Ok",
          showCancelButton: true,
        }).then(function () {
          window.location.reload("/");
        });
      })

      .catch((error) => {
        console.log(error);
        alert("Error al Actualizar" + error);
        window.location.reload("/");
      });
    //send/receive api updates here
    setTableData([...tableData]);
    console.log("post data ");
  });

  const Elimina = useCallback((row) => {
    axios({
      auth: {
        username: "admin.icontec@icontec.org",
        password: "quAngEraMuSTerGerEDE",
      },
      method: "delete",
      url:
        "https://middlewarebackprd.azurewebsites.net/api/v1/ElminaPendiente/" +
        row.getValue("id"),

      data: {},
    })
      .then((Response) => {
        console.log(Response.data);
        Swal.fire({
          title: "Eliminado",
          icon: "success",
          type: "success",
        }).then(function () {
          window.location.reload("/");
        });
      })

      .catch((error) => {
        console.log(error);
        alert("Error al Actualizar" + error);
      });
    //send/receive api updates here
    setTableData([...tableData]);
    console.log("post data ");
  });
  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        filterVariant: "text",
        enableEditing: false,
      },

      {
        accessorKey: "id_certificado",
        header: "id_certificado",
        filterVariant: "text",
        enableEditing: false,
      },

      {
        accessorKey: "modulo",
        header: "Modulo",
        filterVariant: "text",
      },
      {
        accessorKey: "numero_certificado",
        header: "numero_certificado",
        filterVariant: "text",
      },
      {
        accessorKey: "correo",
        header: "correo",
        filterVariant: "text",
      },
      {
        accessorKey: "envio_correo",
        header: "envio_correo",
        filterVariant: "text",
      },
      {
        accessorKey: "documento",
        header: "documento",
        filterVariant: "text",
      },
      {
        accessorKey: "nombre",
        header: "nombre",
        filterVariant: "text",
      },
      {
        accessorKey: "asistio",
        header: "asistio",
        filterVariant: "text",
      },

      {
        accessorKey: "certificado",
        header: "certificado",
        filterVariant: "text",
      },

      {
        accessorKey: "certificado_ingles",
        header: "certificado_ingles",
        filterVariant: "text",
      },
      {
        accessorKey: "alcance",
        header: "alcance",
        filterVariant: "text",
      },
      {
        accessorKey: "alcance_ingles",
        header: "alcance_ingles",
        filterVariant: "text",
      },
      {
        accessorKey: "intensidad",
        header: "intensidad",
        filterVariant: "text",
      },
      {
        accessorKey: "listado_cursos",
        header: "listado_cursos",
        filterVariant: "text",
      },
      {
        accessorKey: "ciudad",
        header: "ciudad",
        filterVariant: "text",
      },
      {
        accessorKey: "pais",
        header: "pais",
        filterVariant: "text",
      },
      {
        accessorKey: "fecha_certificado",
        header: "fecha_certificado",
        filterVariant: "text",
      },
      {
        accessorKey: "fecha_aprobacion",
        header: "fecha_aprobacion",
        filterVariant: "text",
      },
      {
        accessorKey: "fecha_ultima_modificacion",
        header: "fecha_ultima_modificacion",
        filterVariant: "text",
      },
      {
        accessorKey: "fecha_renovacion",
        header: "fecha_renovacion",
        filterVariant: "text",
      },
      {
        accessorKey: "fecha_vencimiento",
        header: "fecha_vencimiento",
        filterVariant: "text",
      },
      {
        accessorKey: "fecha_impresion",
        header: "fecha_impresion",
        filterVariant: "text",
      },
      {
        accessorKey: "direccion",
        header: "direccion",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_1",
        header: "dato_1",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_2",
        header: "dato_2",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_3",
        header: "dato_3",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_4",
        header: "dato_4",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_5",
        header: "dato_5",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_6",
        header: "dato_6",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_7",
        header: "dato_7",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_8",
        header: "dato_8",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_9",
        header: "dato_9",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_10",
        header: "dato_10",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_11",
        header: "dato_11",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_12",
        header: "dato_12",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_13",
        header: "dato_13",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_14",
        header: "dato_14",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_15",
        header: "dato_15",
        filterVariant: "text",
      },
      {
        accessorKey: "estado",
        header: "Estado",
        filterVariant: "text",
      },
      {
        accessorKey: "emitido",
        header: "Emitido",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_16",
        header: "dato_16",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_17",
        header: "dato_17",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_18",
        header: "dato_18",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_19",
        header: "dato_19",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_20",
        header: "dato_20",
        filterVariant: "text",
      },
      {
        accessorKey: "regional",
        header: "regional",
        filterVariant: "text",
      },
    ],
    []
  );
  const handleSaveRow = async ({ exitEditingMode, row, values }) => {
    axios({
      auth: {
        username: "admin.icontec@icontec.org",
        password: "quAngEraMuSTerGerEDE",
      },
      method: "put",
      url:
        "https://middlewarebackprd.azurewebsites.net/api/v1/ValidaCertificado?Id=" +
        values.id,
      data: values,
    })
      .then((Response) => {
        console.log(Response.data);
        alert(Response.data);
        window.location.reload("/");
      })
      .catch((error) => {
        console.log(error);
        alert("Error al Actualizar" + error);
      });

    //send/receive api updates here
    setTableData([...tableData]);
    exitEditingMode(); //required to exit editing mode
    console.log("post data ");
    console.log(values.id);
  };
  const cargar = (e) => {
    window.location.href = "/#/CargaTesoreria";
  };

  return (
    <div>
      <button
        className="btn btn-primary btn-user "
        onClick={() => cargar()}
        fontFamily="Catamaran, sans-serif"
      >
        Cargar Anexos
      </button>
      <MaterialReactTable
        title="indicadores"
        localization={MRT_Localization_ES}
        enableStickyHeader
        columns={columns}
        data={list1}
        enableRowActions
        editingMode="modal" //default
        enableStickyFooter
        enableEditing
        onEditingRowSave={handleSaveRow}
        initialState={{
          columnVisibility: {
            dato_1: false,
            dato_2: false,
            dato_3: false,
            dato_4: false,
            dato_5: false,
            dato_6: false,
            dato_7: false,
            dato_8: false,
            dato_9: false,
            dato_10: false,
            dato_11: false,
            dato_12: false,
            dato_13: false,
            dato_14: false,
            dato_15: false,
            dato_16: false,
            dato_17: false,
            dato_18: false,
            dato_19: false,
            dato_20: false,
            id_certificado: false,
            id: false,
            modulo: false,
            envio_correo: false,
            asistio: false,
            certificado_ingles: false,
            alcance: false,
            alcance_ingles: false,
            intensidad: false,
            listado_cursos: false,
            fecha_aprobacion: false,
            fecha_ultima_modificacion: false,
            fecha_renovacion: false,
            fecha_vencimiento: false,
            fecha_impresion: false,
            emitido: false,

            showColumnFilters: true,
          },
        }}
        renderDetailPanel={({ row }) => (
          <Box
            sx={{
              display: "grid",
              margin: "auto",
              gridTemplateColumns: "1fr 1fr",
              width: "100%",
            }}
          >
            <Typography>
              <b>Alcance:</b> {row.original.alcance}
            </Typography>
            <Typography>
              <b>Certificado:</b> {row.original.certificado}
            </Typography>
          </Box>
        )}
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: "flex", gap: "1rem" }}>
            {row.getValue("estado") != "Emitido" ? (
              <Tooltip arrow placement="right" title="Emitir">
                <IconButton color="primary" onClick={() => EmitirValid(row)}>
                  <SendAndArchiveSharp />
                </IconButton>
              </Tooltip>
            ) : (
              <h></h>
            )}
            {row.getValue("estado") != "Emitido" ? (
              <Tooltip arrow placement="right" title="Delete">
                <IconButton color="error" onClick={() => Elimina(row)}>
                  <Delete />
                </IconButton>
              </Tooltip>
            ) : (
              <h></h>
            )}
          </Box>
        )}
      />
    </div>
  );
};

export default InfoTesoreria;
