import React, { useState, useEffect } from "react";
import {
  PDFViewer,
  Document,
  Page,
  Text,
  PDFDownloadLink,
  Font,
  StyleSheet,
  View,
  Image,
} from "@react-pdf/renderer";
import axios from "axios";
import { useParams } from "react-router-dom";
import iqnet from "../../img/client-2.png";
import icontec from "../../img/lgo.png";
import QRCode from "react-qr-code";

const CertificadoInterno = () => {
  let { id } = useParams();
  let { formulario } = useParams();
  // Make sure to replace 'path/to/your/catamaran-font.ttf' with the actual path to your font file
  const catamaranFont = {
    fontFamily: "Catamaran",
    src: "url(path/to/your/catamaran-font.ttf)",
  };

  // Register the font
  // Note: You can also use Font.registerHyphenationCallback() if needed
  Font.register(catamaranFont, { family: "Catamaran" });

  const styles = StyleSheet.create({
    watermarkImage: {
      position: "absolute",
      bottom: 0,
      width: "120%", // Ancho de la imagen al 100% del tamaño de la página
      height: "120%", // Altura automática para mantener la proporción de la imagen
      opacity: 0.5, // Opacidad de la imagen
    },

    page: {
      flexDirection: "row",
      backgroundColor: "#ffffff",
      padding: 20,
      fontFamily: "Catamaran",
    },
    column: {
      width: "50%",
      paddingRight: 10,
    },
    text: {
      fontSize: 18,
      marginBottom: 10,
      textAlign: "center",
      lineHeight: 1.5,
    },
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    boldText: {
      fontWeight: "bold",
    },
    title: {
      fontSize: 22,
      textAlign: "center",
      lineHeight: 1.5,
    },
    author: {
      fontSize: 12,
      margin: 12,
      lineHeight: 1,
    },
    subtitle: {
      fontSize: 16,
      margin: 10,

      textAlign: "center",
      lineHeight: 1.5,
    },
    image: {
      marginVertical: 15,
      marginHorizontal: 100,
    },
    imageLogo: {
      width: 70,
      height: 60,
    },
    imageFoot: {
      width: 130,
      height: 60,
      marginRight: 10,
    },
    header: {
      position: "absolute",
      top: 10,
      left: 480,
      right: 10,
      textAlign: "right",
      fontSize: 12,
    },
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
    },
    tableRow: {
      flexDirection: "row",
      borderBottomWidth: 1,
      borderBottomColor: "#000",
      alignItems: "center",
      height: 20,
      textAlign: "center",
    },

    tableCell: {
      width: "33.33%",
    },
    footerLetra: {
      position: "absolute",
      bottom: 30,
      left: 20,
      top: 560,
      right: 0,
      textAlign: "left",
      fontSize: 12,
      lineHeight: 1.5,
    },
    footerImagen: {
      position: "absolute",
      bottom: 20,
      left: 0,
      right: 10,
      textAlign: "left",
      fontSize: 12,
    },
  });
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://middlewarebackprd.azurewebsites.net/api/v1/CertificadoRegistro/" +
          id,
        {
          auth: {
            username: "admin.icontec@icontec.org",
            password: "quAngEraMuSTerGerEDE",
          },

          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <center>
      <PDFViewer width={"100%"} height="1000">
        <Document>
          <Page style={styles.body}>
            <View style={styles.header}>
              <Image style={styles.imageLogo} src={icontec} />
            </View>
            <Text style={styles.text}></Text>
            <Text style={styles.text}></Text>
            <Text style={styles.text}></Text>
            <Text style={styles.text}></Text>
            <Text style={styles.text}></Text>
            <Text style={styles.title}>Certifica Que:</Text>
            {data.map((item, index) => (
              <Text style={styles.title} key={index}>
                {item.nombre}
              </Text>
            ))}
            {data.map((item, index) => (
              <Text style={styles.subtitle} key={index}>
                {item.documento}
              </Text>
            ))}
            <Text style={styles.subtitle}>Asistió y aprobó el : </Text>
            {data.map((item, index) => (
              <Text style={styles.title} key={index}>
                {item.certificado}
              </Text>
            ))}

            <Text style={styles.subtitle}>
              Con una intensidad horaria de :{" "}
            </Text>
            {data.map((item, index) => (
              <Text style={styles.title} key={index}>
                {item.intensidad} horas
              </Text>
            ))}

            {data.map((item, index) => (
              <View key={index}>
                {item.listado_cursos ? (
                  item.listado_cursos.split("\\n").map((line, lineIndex) => (
                    <Text style={styles.author} key={lineIndex}>
                      {line}
                    </Text>
                  ))
                ) : (
                  <Text style={styles.author}>Sin lista de cursos</Text>
                )}
              </View>
            ))}

            <View style={styles.footerLetra}>
              {data.map((item, index) => (
                <Text style={styles.text} key={index}>
                  {item.ciudad}({item.pais}){item.dato_2}
                </Text>
              ))}
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </center>
  );
};

export default CertificadoInterno;
