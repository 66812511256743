import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../img/PAGWEB-Acreditta-ICONTEC-2024.jpg";
import projImg2 from "../img/PAGWEB-Acreditta-IQNet-2024.jpg";
import projImg3 from "../img/PAGWEB-Acreditta-Anexo-2024.jpg";
import "../css/Anexos.css";
import "animate.css";
import TrackVisibility from "react-on-screen";
import Footer from "./Footer";
import { useParams } from "react-router-dom";
import ImagenIcontec from "../img/LOGO-Icontec-2019_hor_pos.png";
import { useEffect, useState } from "react";
import axios from "axios";
function Anexos() {
  const componentStyle = {
    fontFamily: "Catamaran, sans-serif",
    // other styles
  };
  let params = useParams();
  const Icontec = (e) => {
    const url =
      "https://middlewarebackprd.azurewebsites.net/api/v1/ConsultaSG/" +
      params.certificado;

    // Abre la URL en una nueva pestaña
    window.open(url, "_blank");
  };
  const Iqnet = (e) => {
    const url =
      "https://middlewarebackprd.azurewebsites.net/api/v1/ConsultaIQ/" +
      params.certificado;

    // Abre la URL en una nueva pestaña
    window.open(url, "_blank");
  };
  const Anexo = (e) => {
    const url =
      "https://icy-dune-049f54f0f.4.azurestaticapps.net/#/Informe/" +
      params.certificado;

    // Abre la URL en una nueva pestaña
    window.open(url, "_blank");
  };

  const [list, setList] = useState([]);
  const urlendpoint =
    "https://middlewarebackprd.azurewebsites.net/api/v1/ObtenerNAnexos/" +
    params.certificado;
  useEffect(() => {
    axios({
      auth: {
        username: "admin.icontec@icontec.org",
        password: "quAngEraMuSTerGerEDE",
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      method: "get",

      url: urlendpoint,
    })
      .then((Response) => {
        setList(Response.data);
        console.log(Response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [setList]);

  const imagen = (
    <img
      src={projImg1}
      style={{ width: "200px", height: "auto" }}
      alt="Descripción de la imagen"
    />
  );
  const projects = [
    {
      title: "ICONTEC",
      description: (
        <button
          style={{ fontFamily: "Catamaran, sans-serif" }}
          variant="contained"
          className="btn btn-light"
          color="primary"
          onClick={Icontec}
        >
          Generar
        </button>
      ),
      imgUrl: projImg1,
    },
    {
      title: "IQNET",
      description: (
        <button
          style={{ fontFamily: "Catamaran, sans-serif" }}
          variant="contained"
          className="btn btn-light"
          color="primary"
          onClick={Iqnet}
        >
          Generar
        </button>
      ),
      imgUrl: projImg2,
    },
    list === true
      ? {
          title: "ANEXOS",
          description: (
            <button
              style={{ fontFamily: "Catamaran, sans-serif" }}
              variant="contained"
              className="btn btn-light"
              color="primary"
              onClick={Anexo}
            >
              Generar
            </button>
          ),
          imgUrl: projImg3,
        }
      : {
          title: "NO CUENTA CON ANEXOS",

          imgUrl: projImg3,
        },
  ];

  const [list1, setList1] = useState([]);

  useEffect(() => {
    axios({
      auth: {
        username: "admin.icontec@icontec.org",
        password: "quAngEraMuSTerGerEDE",
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      method: "get",

      url:
        "https://middlewarebackprd.azurewebsites.net/api/v1/InformacionCertificados/" +
        params.certificado,
    })
      .then((Response) => {
        setList1(Response.data);
        console.log(Response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [setList1]);

  const Nombre = list1.map((item) => {
    return item.nombre;
  });
  const Documento = list1.map((item) => {
    return item.documento;
  });
  const FechaCertificado = list1.map((item) => {
    return item.fecha_certificado;
  });
  const FechaVencimiento = list1.map((item) => {
    return item.fecha_vencimiento;
  });
  const Alcance = list1.map((item) => {
    return item.alcance;
  });
  const Localizacion = list1.map((item) => {
    return item.direccion + item.ciudad + item.pais;
  });

  return (
    <div>
      <center>
        <strong>
          <div>
            <div>
              <h2
                style={{
                  color: "#0085CA",
                  display: "inline-block",
                }}
              >
                Certifican que:
              </h2>{" "}
            </div>
            <br></br>
          </div>
          <h3 style={{ color: "#0085CA" }}>{Nombre}</h3>
          <br></br>
          <h5 style={{ color: "#0085CA" }}>{Documento}</h5>
          <br></br>
          <h5 style={{ color: "#002E5D" }}>
            Registra con el siguiente certificado: {params.certificado}
          </h5>

          <br></br>
        </strong>
      </center>{" "}
      <section className="project" id="project">
        <br></br> <br></br>
        <br></br>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="skill-bx wow zoomIn">
                <Container>
                  <Row>
                    <Col size={12}>
                      <TrackVisibility>
                        {({ isVisible }) => (
                          <div
                            className={
                              isVisible
                                ? "animate__animated animate__fadeIn"
                                : ""
                            }
                          >
                            <Tab.Container
                              id="projects-tabs"
                              defaultActiveKey="first"
                            >
                              <Tab.Content
                                id="slideInUp"
                                className={
                                  isVisible
                                    ? "animate__animated animate__slideInUp"
                                    : ""
                                }
                              >
                                <Tab.Pane eventKey="first">
                                  <Row>
                                    {projects.map((project, index) => {
                                      return (
                                        <ProjectCard key={index} {...project} />
                                      );
                                    })}
                                  </Row>
                                </Tab.Pane>
                                <Tab.Pane eventKey="section"></Tab.Pane>
                              </Tab.Content>
                            </Tab.Container>
                          </div>
                        )}
                      </TrackVisibility>
                    </Col>
                  </Row>
                </Container>
                <img className="background-image-right"></img>
              </div>
            </div>
          </div>
        </div>
      </section>
      <center>
        <Footer />
      </center>
    </div>
  );
}
export default Anexos;
