import PropTypes from "prop-types";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
// @mui
import { styled, alpha } from "@mui/material/styles";
import {
  Box,
  Link,
  Button,
  Drawer,
  Typography,
  Avatar,
  Stack,
} from "@mui/material";
// mock
import account from "../../../_mock/account";
// hooks
import useResponsive from "../../../hooks/useResponsive";
// components
import Logo from "../../../components/logo";
import NavSection from "../../../components/nav-section";
//
import navConfig from "./config";
import Cookie from "universal-cookie";

import Scrollbar from "simplebar-react";
import IcontecWH from "../../../img/LOGO-Icontec-2019_hor_white (1).png";
import navConfigTesoreria from "./configTesoreria";
import navConfigGestor from "./configGestor";
import navConfigNuevoUsuario from "./configNuevoUsuario";

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;
const cookie = new Cookie();
const StyledAccount = styled("div")(({ theme }) => ({
  alignItems: "center",
  padding: theme.spacing(1, 1.5),

  backgroundColor: "#002E5D",
}));
// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();

  const isDesktop = useResponsive("up", "lg");

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          maxHeight: "calc(100vh - 64px)", // Ajusta esta altura según tus necesidades
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box sx={{ width: "auto" }}>
        <StyledAccount>
          <center>
            <img src={IcontecWH} alt="photoURL" width={"160px"} />

            <font color="#FFFFFF">
              <h3>Huella</h3>
            </font>
          </center>
        </StyledAccount>
        <hr />
      </Box>

      {cookie.get("tipoUsuario") == 1 ? (
        <NavSection data={navConfig} />
      ) : cookie.get("tipoUsuario") == 2 ? (
        <NavSection data={navConfigTesoreria} />
      ) : cookie.get("tipoUsuario") == 5 || cookie.get("tipoUsuario") == 6 ? (
        <NavSection data={navConfigGestor} />
      ) : cookie.get("tipoUsuario") == 7 ? (
        <NavSection data={navConfigNuevoUsuario} />
      ) : (
        <h></h>
      )}

      <Box sx={{}} />
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: "background.default",
              borderRightStyle: "dashed",
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
