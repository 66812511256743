import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import Cookies from "universal-cookie";
// component
import SvgColor from "../../../components/svg-color";

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);
const cookie = new Cookies();
const casos = "/InfoCasos/";

const navConfigNuevoUsuario = [
  {
    title: "Consultar Certificados",
    path: "/InfoCertificados/" + cookie.get("correo"),
    icon: <AiIcons.AiFillFileAdd />,
  },
];

export default navConfigNuevoUsuario;
