import React, { useMemo, useState, useCallback } from "react";
import axios from "axios";
import "../../css/sb-admin-2.min.css";
import { TextField, Button, CircularProgress } from "@mui/material";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import { MRT_Localization_ES } from "material-react-table/locales/es";
import { MaterialReactTable } from "material-react-table";
import * as XLSX from "xlsx";
import Swal from "sweetalert2";
import { PieChart, Pie, Tooltip, Legend, Cell } from "recharts";
import SearchIcon from "@mui/icons-material/Search";

function Reporte(props) {
  const columns = useMemo(
    () => [
      {
        accessorKey: "id_certificado",
        header: "Hash",
        filterVariant: "text",
        enableEditing: false,
      },
      {
        accessorKey: "numero_certificado",
        header: "Número certificado",
        filterVariant: "text",
      },
      { accessorKey: "correo", header: "Correo", filterVariant: "text" },
      { accessorKey: "documento", header: "Documento", filterVariant: "text" },
      { accessorKey: "nombre", header: "Nombre", filterVariant: "text" },
      {
        accessorKey: "certificado",
        header: "Certificado",
        filterVariant: "text",
      },
      { accessorKey: "ciudad", header: "Ciudad", filterVariant: "text" },
      {
        accessorKey: "fecha_certificado",
        header: "Fecha cert.",
        filterVariant: "text",
      },
      {
        accessorKey: "fecha_aprobacion",
        header: "Fecha aprob.",
        filterVariant: "text",
      },
      {
        accessorKey: "fecha_ultima_modificacion",
        header: "Fecha última mod.",
        filterVariant: "text",
      },
      {
        accessorKey: "fecha_renovacion",
        header: "Fecha renovación",
        filterVariant: "text",
      },
      {
        accessorKey: "fecha_vencimiento",
        header: "Fecha vencimiento",
        filterVariant: "text",
      },
      {
        accessorKey: "fecha_impresion",
        header: "Fecha impresión",
        filterVariant: "text",
      },
      { accessorKey: "estado", header: "Estado", filterVariant: "text" },
      { accessorKey: "emitido", header: "Emitido", filterVariant: "text" },
      { accessorKey: "regional", header: "Regional", filterVariant: "text" },
    ],
    []
  );

  const [list1, setList1] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [error, setError] = useState(null);
  const [setYear, setPostYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState("");

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://middlewarebackprd.azurewebsites.net/api/v1/RegistrosMes/${setYear}/${selectedMonth}`,
        {
          auth: {
            username: "admin.icontec@icontec.org",
            password: "quAngEraMuSTerGerEDE",
          },
        }
      );
      if (response.data && response.data.length > 0) {
        setList1(response.data[0].certificados);
        setChartData(response.data[0]);
        setError(null);
      } else {
        Swal.fire({
          icon: "warning",
          title: "No se encontró información",
          text: "La información ingresada no corresponde a un certificado válido.",
        });
        setList1([]);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Ocurrió un error, verifique los campos.",
      });
      setError("Hubo un error al cargar los datos.");
    } finally {
      setLoading(false);
    }
  }, [selectedMonth, setYear]);

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(list1);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Certificados");
    XLSX.writeFile(workbook, "certificados.xlsx");
  };

  const COLORS = [
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#FF8042",
    "#8884D8",
    "#FF0000",
  ];

  const pieData = [
    { name: "Send", value: chartData.send, color: COLORS[0] },
    { name: "Sia", value: chartData.sia, color: COLORS[1] },
    { name: "Campus", value: chartData.campus, color: COLORS[2] },
    { name: "Send Acreditta", value: chartData.sendAcredita, color: COLORS[3] },
    { name: "Sia Acreditta", value: chartData.siaAcredita, color: COLORS[4] },
    {
      name: "Campus Acredita",
      value: chartData.campusAcredita,
      color: COLORS[5],
    },
  ];

  const headerLabels = {
    totalActivos: "Total Activos",
    totalRevocados: "Total Revocados",
    fechaConMasRegistros: "Fecha con Más Registros",
    fechaConMenosRegistros: "Fecha con Menos Registros",
  };

  const headerLabels2 = {
    totalregistros: "Total Registros Acreditta - Huella",
    registrosAcredita: "Registros Acreditta",
  };
  const headerLabels3 = {
    totalActivosAcredita: "Total Activos Acreditta",
    totalRevocadosAcredita: "Total Revocados Acreditta",
    totalActivosInterno: "Total Activos Interno",
    totalRevocadosInterno: "Total Revocados Interno",
  };

  const months = [
    { value: "1", label: "Enero" },
    { value: "2", label: "Febrero" },
    { value: "3", label: "Marzo" },
    { value: "4", label: "Abril" },
    { value: "5", label: "Mayo" },
    { value: "6", label: "Junio" },
    { value: "7", label: "Julio" },
    { value: "8", label: "Agosto" },
    { value: "9", label: "Septiembre" },
    { value: "10", label: "Octubre" },
    { value: "11", label: "Noviembre" },
    { value: "12", label: "Diciembre" },
    { value: "0", label: "Todos" },
  ];

  const handleInputChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  return (
    <div>
      <div className="text-center">
        <h6 className="m-0 font-weight-bold text-primary">
          INFORMACIÓN DE LOS CERTIFICADOS
        </h6>
        <hr />
        <center>
          <div className="date-fields">
            <FormControl fullWidth variant="outlined" margin="normal">
              <InputLabel id="meses-label">Mes de consulta</InputLabel>
              <Select
                value={selectedMonth}
                onChange={handleInputChange}
                label="Fecha Inicio"
              >
                <MenuItem value="">
                  <em>Selecciona un mes</em>
                </MenuItem>
                {months.map((month) => (
                  <MenuItem key={month.value} value={month.value}>
                    {month.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              type="text"
              inputProps={{ maxLength: 4 }}
              onChange={(e) => setPostYear(e.target.value)}
            />
            <div className="button-wrapper">
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={fetchData}
                startIcon={<SearchIcon />}
                title="Consultar"
                disabled={loading} // Deshabilitar el botón mientras carga
              >
                Consultar
              </Button>
              {loading && <CircularProgress size={24} />}
            </div>
          </div>
        </center>
        <div className="form-group row">
          {Object.keys(headerLabels).map((key, index) => (
            <div className="col-lg-3" key={index}>
              <div className="card o-hidden border-12 shadow-lg my-2">
                <div className="card-body p-0">
                  <div className="card-header py-6">
                    <h6 className="m-0 font-weight-bold text-primary">
                      {headerLabels[key]}
                    </h6>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive black-text">
                      {chartData[key]}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="form-group row">
          <div className="col-lg-6">
            <div className="card o-hidden border-12 shadow-lg my-2">
              <div className="card-body p-0">
                <div className="card-header py-6">
                  <h6 className="m-0 font-weight-bold text-primary">
                    CERTIFICADOS
                  </h6>
                </div>
                <div>
                  <h3 className="m-0 font-weight-bold text-primary">
                    Certificado x Legado
                  </h3>
                  {loading && <p>Cargando datos...</p>}
                  {error && <p>{error}</p>}
                  {chartData && (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <PieChart width={500} height={500}>
                        <Pie
                          data={pieData}
                          dataKey="value"
                          nameKey="name"
                          cx="50%"
                          cy="50%"
                          outerRadius={120}
                          fill="#8884d8"
                          label={(entry) => entry.name}
                          isAnimationActive={false}
                        >
                          {pieData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color} />
                          ))}
                        </Pie>
                        <Tooltip />
                        <Legend />
                      </PieChart>
                    </div>
                  )}
                </div>
                <br></br> <br></br>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="card o-hidden border-12 shadow-lg my-2">
              <div className="card-body p-0">
                <div className="card-header py-6">
                  <div className="table-responsive">
                    <h6 className="m-0 font-weight-bold text-primary">
                      CERTIFICADOS
                    </h6>
                    <hr />
                    <table
                      className="table table-bordered"
                      id="dataTable"
                      width="100%"
                      cellSpacing="0"
                    >
                      <thead>
                        <tr>
                          <th style={{ color: "#0085CA" }}>Legado</th>
                          <th style={{ color: "#0085CA" }}>Acreditta</th>
                          <th style={{ color: "#0085CA" }}>Interno</th>
                          <th style={{ color: "#0085CA" }}>
                            Revocado Acreditta
                          </th>
                          <th style={{ color: "#0085CA" }}>Revocado Interno</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style={{ color: "black" }}>Send: </td>
                          <td>{chartData.sendAcredita}</td>
                          <td>{chartData.send}</td>
                          <td>{chartData.sendRevocadoAcredita}</td>
                          <td>{chartData.sendRevocado}</td>
                        </tr>
                        <tr>
                          <td style={{ color: "black" }}>Campus: </td>
                          <td>{chartData.campusAcredita}</td>
                          <td>{chartData.campus}</td>
                          <td>{chartData.campusAcreditaRevocado}</td>
                          <td>{chartData.campusRevocado}</td>
                        </tr>
                        <tr>
                          <td style={{ color: "black" }}>Sia : </td>
                          <td>{chartData.siaAcredita}</td>
                          <td>{chartData.sia}</td>
                          <td>{chartData.siaAcreditaRevocado}</td>
                          <td>{chartData.siaRevocado}</td>
                        </tr>
                        <tr>
                          <td style={{ color: "black" }} colSpan={1}>
                            Validación y Verificacion :{" "}
                          </td>
                          <td>{chartData.vyv}</td>
                          <td>0</td>
                          <td>{chartData.vyvRevocadoAcredita}</td>
                          <td>{chartData.vyvRevoInterno}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {Object.keys(headerLabels2).map((key, index) => (
              <div className="card o-hidden border-12 shadow-lg my-2">
                <div className="card-body p-0">
                  <div className="card-header py-6">
                    <h6 className="m-0 font-weight-bold text-primary">
                      {headerLabels2[key]}
                    </h6>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive black-text">
                      {chartData[key]}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="form-group row">
          {Object.keys(headerLabels3).map((key, index) => (
            <div className="col-lg-3" key={index}>
              <div className="card o-hidden border-12 shadow-lg my-2">
                <div className="card-body p-0">
                  <div className="card-header py-6">
                    <h6 className="m-0 font-weight-bold text-primary">
                      {headerLabels3[key]}
                    </h6>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive black-text">
                      {chartData[key]}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <Button
          variant="contained"
          color="secondary"
          size="large"
          onClick={exportToExcel}
        >
          Exportar a Excel
        </Button>
      </div>
      <hr />

      <MaterialReactTable
        columns={columns}
        data={list1}
        localization={MRT_Localization_ES}
        initialState={{ density: "compact" }}
      />
    </div>
  );
}

export default Reporte;
