import { Grid, Paper, Button, MenuItem } from "@mui/material";
import React, { useCallback, useState, useMemo, useEffect } from "react";
import { Form, Field } from "react-final-form";
import { TextField, Select } from "final-form-material-ui";
import Swal from "sweetalert2";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import JoditEditor from "jodit-react";

function CrearParametros() {
  const [content, setContent] = useState("");
  const [logs, setLogs] = useState([]);
  const appendLog = useCallback(
    (message) => {
      console.log("logs = ", logs);
      const newLogs = [...logs, message];
      setLogs(newLogs);
    },
    [logs, setLogs]
  );

  const config = useMemo(
    () => ({
      readonly: false,
    }),
    []
  );

  const onChange = useCallback(
    (newContent) => {
      appendLog(`onChange triggered with ${newContent}`);
    },
    [appendLog]
  );

  useEffect(() => {
    console.log("onChange = ", onChange);
  }, [onChange]);

  const onBlur = useCallback(
    (newContent) => {
      appendLog(`onBlur triggered with ${newContent}`);
      setContent(newContent);
    },
    [appendLog, setContent]
  );

  const [editorHtml, setEditorHtml] = useState("");

  // Función para manejar cambios en el editor
  const handleEditorChange = (html) => {
    setEditorHtml(html);
  };
  console.log(editorHtml);
  function redirige() {
    window.location.href = "/#/PlantillasCorreo";
  }
  const onSubmit = async (values) => {
    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    await sleep(300);

    axios({
      auth: {
        username: "admin.icontec@icontec.org",
        password: "quAngEraMuSTerGerEDE",
      },
      method: "post",
      url: "https://middlewarebackprd.azurewebsites.net/api/v1/CreaPlantillaEmail",
      data: {
        legado: values.legado,
        mensaje: content,
        imagen: values.url + "",
      },
    })
      .then((Response) => {
        Swal.fire({
          title: Response.data,
          icon: "success",
          type: "success",
        }).then((window.location.href = "/#/PlantillasCorreo"));
      })

      .catch((error) => {
        Swal.fire({
          title: error,
          icon: "error",
          text: "Revise los campos e intente de nuevo!",
          type: "error",
        }).then(redirige);
      });
  };
  const validate = (values) => {
    const errors = {};

    if (!values.legado) {
      errors.legado = "Required";
    }

    console.log(errors);
    return errors;
  };

  return (
    <div>
      <div style={{ padding: 16, margin: "auto", maxWidth: 800 }}>
        <font color="">
          <h2>Crear Parametros</h2>{" "}
        </font>
        <Form
          onSubmit={onSubmit}
          initialValues={{}}
          validate={validate}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Paper style={{ padding: 16 }}>
                <Grid container alignItems="flex-start" spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      required
                      name="legado"
                      component={TextField}
                      type="text"
                      label="Legado"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      required
                      name="url"
                      component={TextField}
                      type="text"
                      label="url Imagen:"
                    />
                  </Grid>
                  <Grid item md={12}>
                    <JoditEditor
                      value={content}
                      config={config}
                      tabIndex={1}
                      onBlur={onBlur}
                      onChange={onChange}
                    />
                    <br></br>
                  </Grid>

                  <Grid item style={{ marginTop: 16 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={submitting}
                      label="siguiente"
                    >
                      CREAR
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </form>
          )}
        />
      </div>
    </div>
  );
}

export default CrearParametros;
