import { Helmet } from "react-helmet-async";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import { Button, Typography, Container, Box } from "@mui/material";
import img from "../img/Simbolo_ICONTEC-CMYK_2019-pos.png";
// ----------------------------------------------------------------------

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Page404() {
  return (
    <>
      <Helmet>
        <title> 404 Page Not Found </title>
      </Helmet>

      <Container>
        <StyledContent sx={{ textAlign: "center", alignItems: "center" }}>
          <Typography variant="h3" paragraph>
            Lo siento la pagina no esta disponible
          </Typography>

          <Typography sx={{ color: "text.secondary" }}>
            Lo sentimos por favor comuniquese con el adminstrador del sistema
          </Typography>

          <Box
            component="img"
            src={img}
            sx={{ width: 220, height: 220, mx: "auto", my: { xs: 10, sm: 7 } }}
          />

          <Button
            to="/"
            size="large"
            variant="contained"
            component={RouterLink}
          >
            Volver
          </Button>
        </StyledContent>
      </Container>
    </>
  );
}
