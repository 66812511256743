import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import "../css/sb-admin-2.min.css";
import { Stack, TextField } from "@mui/material";

function CargaTesoreria(props) {
  const [isLoading, setLoading] = useState(false);

  const [archivos, setArchivos] = useState(null);
  const subirArchivos = (e) => {
    setArchivos(e);
  };
  const insertarArchivos = async () => {
    const username = "admin.icontec@icontec.org";
    const password = "quAngEraMuSTerGerEDE";

    // Create a base64-encoded credential string
    const credentials = btoa(`${username}:${password}`);
    const authHeader = `Basic ${credentials}`;
    const f = new FormData();
    for (let index = 0; index < archivos.length; index++) {
      f.append("files", archivos[index]);
    }
    await axios
      .post(
        "https://middlewarebackprd.azurewebsites.net/api/v1/CargarCartera",
        f,
        {
          headers: {
            Authorization: authHeader,
            "Content-Type": "multipart/form-data", // Set the content type for FormData
          },
        }
      )

      .then((response) => {
        console.log(response.data);
        alert("Cargado con exito");
        window.location.reload("/");
      })
      .catch((error) => {
        window.alert(error);
        console.log(error);
      });
  };

  return (
    <div>
      <div className="container">
        <div className="text-center">
          <div class="container-fluid">
            <div class="card shadow mb-4">
              <div class="card-header py-3">
                <h6 class="m-0 font-weight-bold text-primary">Adjuntar</h6>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <div className="form-group row"> </div>

                  <table
                    class="table table-bordered"
                    id="dataTable"
                    width="100%"
                    cellspacing="0"
                  >
                    <input
                      type="file"
                      name="files"
                      className="btn btn-secondary"
                      onChange={(e) => subirArchivos(e.target.files)}
                    />
                    <br></br>
                    <hr />
                    {isLoading ? (
                      ""
                    ) : (
                      <button
                        className="btn btn-primary btn-user btn-block"
                        onClick={() => insertarArchivos()}
                        fontFamily="Catamaran, sans-serif"
                      >
                        Guardar
                      </button>
                    )}
                    {isLoading ? (
                      <div>
                        <h3>cargando..</h3>
                      </div>
                    ) : (
                      ""
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default CargaTesoreria;
