import { Grid, Paper, Button, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Form, Field } from "react-final-form";
import { TextField, Select } from "final-form-material-ui";
import Swal from "sweetalert2";
import axios from "axios";

function CrearModulo() {
  const [plantilla, setPlantilla] = useState([]);
  const [list, setList] = useState([]);
  useEffect(() => {
    axios({
      auth: {
        username: "admin.icontec@icontec.org",
        password: "quAngEraMuSTerGerEDE",
      },
      method: "get",
      url: "https://middlewarebackprd.azurewebsites.net/api/v1/ConsultaPlantillas",
    })
      .then((Response) => {
        setList(Response.data);
        console.log(Response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [setList]);
  function redirige() {
    window.location.href = "/#/InfoModulo";
  }
  const onSubmit = async (values) => {
    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    await sleep(300);

    axios({
      auth: {
        username: "admin.icontec@icontec.org",
        password: "quAngEraMuSTerGerEDE",
      },
      method: "post",
      url: "https://middlewarebackprd.azurewebsites.net/api/v1/CrearModulo",
      data: {
        codigo: values.codigo,
        legado: values.legado,
        nombre: values.nombre,
        descripcion: values.descripcion,
        modulo: values.modulo,
        plantilla: plantilla,
        tipo: values.tipo,
      },
    })
      .then((Response) => {
        Swal.fire({
          title: Response.data,
          icon: "success",
          type: "success",
        }).then((window.location.href = "/#/InfoModulo"));
      })

      .catch((error) => {
        Swal.fire({
          title: error,
          icon: "error",
          text: "Revise los campos e intente de nuevo!",
          type: "error",
        }).then(redirige);
      });
  };
  const validate = (values) => {
    const errors = {};
    if (!values.codigo) {
      errors.codigo = "Required";
    }

    if (!values.legado) {
      errors.legado = "Required";
    }

    if (!values.nombre) {
      errors.nombre = "Required";
    }
    if (!values.descripcion) {
      errors.descripcion = "Required";
    }
    if (!values.modulo) {
      errors.modulo = "Required";
    }
    if (!values.tipo) {
      errors.tipo = "Required";
    }

    console.log(errors);
    return errors;
  };

  return (
    <div>
      <div style={{ padding: 16, margin: "auto", maxWidth: 800 }}>
        <font color="">
          <h2>CREAR MODULO</h2>{" "}
        </font>
        <Form
          onSubmit={onSubmit}
          initialValues={{}}
          validate={validate}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Paper style={{ padding: 16 }}>
                <Grid container alignItems="flex-start" spacing={2}>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="codigo"
                      component={TextField}
                      type="text"
                      label="Codigo"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="legado"
                      component={TextField}
                      type="text"
                      label="Legado"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      required
                      name="nombre"
                      component={TextField}
                      type="text"
                      label="nombre"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      required
                      name="descripcion"
                      component={TextField}
                      type="text"
                      label="Descripcion:"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      required
                      name="modulo"
                      component={TextField}
                      type="text"
                      label="Modulo:"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <select
                      className="btn btn-secondary btn-user btn-block"
                      onChange={(e) => setPlantilla(e.target.value)}
                    >
                      <option value="">Seleccione la plantilla</option>
                      {list.map((item) => (
                        <option value={item.nombreArchivo}>
                          {item.nombre}
                        </option>
                      ))}
                    </select>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      name="tipo"
                      component={Select}
                      label="Seleccione el tipo"
                      formControlProps={{ fullWidth: true }}
                    >
                      <MenuItem value="IQ">IQ</MenuItem>
                      <MenuItem value="ICONTEC">ICONTEC</MenuItem>
                    </Field>
                  </Grid>

                  <hr />
                  <Grid item style={{ marginTop: 16 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={submitting}
                      label="siguiente"
                    >
                      CREAR
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </form>
          )}
        />
      </div>
    </div>
  );
}

export default CrearModulo;
