import React, { useState, useEffect } from "react";
import {
  PDFViewer,
  Document,
  Page,
  Text,
  PDFDownloadLink,
  Font,
  StyleSheet,
  View,
  Image,
} from "@react-pdf/renderer";
import axios from "axios";
import { useParams } from "react-router-dom";
import header from "../img/LOGO-Icontec-2019_hor_pos.png";
import fondoAnexo from "../img/CERTIFICADO-Icontec-Anexos.png";
import firma from "../img/firma.png";

const InformePDF = () => {
  let { id } = useParams();
  Font.register({
    family: "Lato",
    src: "https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjx4wWw.ttf",
  });

  const styles = StyleSheet.create({
    page: {
      flexDirection: "row",
      backgroundColor: "#ffffff",
      padding: 30,
    },
    codigoFecha: {
      fontSize: 10,
    },
    background: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      opacity: 0.9,
    },
    column: {
      width: "50%",
      paddingRight: 10,
    },
    text: {
      margin: 12,
      fontSize: 12,
      textAlign: "center",
      fontFamily: "Lato",
    },
    body: {
      paddingTop: 55,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    title: {
      fontSize: 18,
      textAlign: "center",
      fontFamily: "Lato",
    },
    subtitleTitle: {
      fontSize: 14,
      margin: 12,
      textAlign: "center",
      fontFamily: "Lato",
    },
    header: {
      position: "absolute",
      top: 10,
      left: 10,
      right: 10,
      textAlign: "left",
      fontSize: 12,
      fontFamily: "Lato",
    },
    table: {
      display: "table",
      width: "100%",
    },
    tableRow: {
      flexDirection: "row",
      width: "100%",
    },
    headerCellCabeceraUno: {
      width: "30%",
      fontSize: 9,
      textAlign: "center",
      fontFamily: "Lato",
      border: 0.5,
      fontWeight: "bold",
      padding: 5,
      lineHeight: 1.2,
    },
    headerCellCabeceraDos: {
      width: "50%",
      fontSize: 9,
      textAlign: "center",
      fontFamily: "Lato",
      border: 0.5,
      fontWeight: "bold",
      padding: 5,
    },
    headerCellprimeras: {
      width: "30%",
      fontSize: 8,
      color: "#2A2A2A",
      border: 0.5,
      textAlign: "justify",
      fontFamily: "Lato",
      padding: 5,
    },
    headerCellDos: {
      width: "50%",
      fontSize: 9,
      color: "#2A2A2A",
      border: 0.5,
      textAlign: "justify",
      fontFamily: "Lato",
      padding: 5,
    },
    footerLetraDerecha: {
      position: "absolute",
      bottom: 90,
      right: 40,
      textAlign: "right",
      fontSize: 12,
      fontFamily: "Lato",
    },
    imageLogofoot: {
      width: 150,
      height: 70,
    },
    codigoFecha: {
      fontSize: 10,
    },
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 30,
      right: 20,
      textAlign: "right",
      color: "grey",
    },
    version: {
      position: "absolute",
      fontSize: 12,
      bottom: 45,
      right: 20,
      textAlign: "right",
      color: "grey",
    },
    headerCellCabeceraFoot: {
      width: "45%",
      fontSize: 11,
      textAlign: "left",
      fontFamily: "Lato",
      bottom: "auto",
    },
  });

  const [data, setData] = useState([]);
  const [firstLine, setFirstLine] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://middlewarebackprd.azurewebsites.net/api/v1/ObtenerAnexos/" +
          id,
        {
          auth: {
            username: "admin.icontec@icontec.org",
            password: "quAngEraMuSTerGerEDE",
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setData(response.data);
      if (response.data && response.data.length > 0) {
        setFirstLine(response.data[0]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <center>
      <PDFViewer width={"100%"} height="1700">
        <Document>
          <Page style={styles.body} wrap>
            <Image style={styles.background} src={fondoAnexo} fixed />
            <Text style={styles.title}>
              {firstLine && <Text>{firstLine.nombre}</Text>}
            </Text>
            <Text style={styles.subtitleTitle}>
              Anexo A Certificado
              {firstLine && (
                <Text>
                  {" "}
                  {" " +
                    firstLine.numero_certificado +
                    " / CO-" +
                    firstLine.numero_certificado}
                </Text>
              )}
            </Text>
            <View>
              <View style={styles.tableRow}>
                <Text style={styles.headerCellCabeceraUno}>
                  Sede y Direcciones de los sitios permanentes diferentes a la
                  sede principal
                </Text>
                <Text style={styles.headerCellCabeceraUno}>Localización</Text>
                <Text style={styles.headerCellCabeceraDos}>
                  Actividades del alcance del sistema de gestión desarrolladas
                  en este sitio
                </Text>
              </View>
              {data.map((item) => {
                const splitDato1 = item.dato_1
                  .split(" ")
                  .reduce((acc, word) => {
                    if (
                      acc.length === 0 ||
                      acc[acc.length - 1].length + word.length > 20
                    ) {
                      acc.push(word);
                    } else {
                      acc[acc.length - 1] += " " + word;
                    }
                    return acc;
                  }, []);

                return (
                  <View style={styles.tableRow} key={item.id}>
                    <View style={styles.headerCellprimeras}>
                      {splitDato1.map((line, index) => (
                        <Text key={index}>{line}</Text>
                      ))}
                    </View>
                    <Text style={styles.headerCellprimeras}>{item.dato_2}</Text>
                    <Text style={styles.headerCellDos}>{item.dato_3}</Text>
                  </View>
                );
              })}
            </View>
            <Text style={{ marginBottom: 12 }}></Text>
            <Text style={{ marginBottom: 12 }}></Text>
            <View>
              <View style={styles.tableRow}>
                <Text style={styles.headerCellCabeceraFoot}>
                  Fecha Otorgamiento
                </Text>
                <Text style={styles.headerCellCabeceraFoot}>
                  {" "}
                  {firstLine && firstLine.fecha_certificado ? (
                    <Text style={styles.codigoFecha}>
                      {firstLine.fecha_certificado}
                      {/* Aquí puedes imprimir otras propiedades si es necesario */}
                    </Text>
                  ) : (
                    "-"
                  )}
                </Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.headerCellCabeceraFoot}>
                  Fecha Vencimiento ciclo previo:
                </Text>
                <Text style={styles.headerCellCabeceraFoot}>
                  {" "}
                  {firstLine && firstLine.datos3 ? (
                    <Text style={styles.codigoFecha}>
                      {firstLine.datos3}
                      {/* Aquí puedes imprimir otras propiedades si es necesario */}
                    </Text>
                  ) : (
                    "-"
                  )}
                </Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.headerCellCabeceraFoot}>
                  Fecha Inicio del ciclo actual de certificación:
                </Text>
                <Text style={styles.headerCellCabeceraFoot}>
                  {" "}
                  {firstLine && firstLine.fInicioCActualCert ? (
                    <Text style={styles.codigoFecha}>
                      {firstLine.fInicioCActualCert}
                      {/* Aquí puedes imprimir otras propiedades si es necesario */}
                    </Text>
                  ) : (
                    "-"
                  )}
                </Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.headerCellCabeceraFoot}>
                  Fecha Vencimiento ciclo actual:
                </Text>
                <Text style={styles.headerCellCabeceraFoot}>
                  {" "}
                  {firstLine && firstLine.fecha_vencimiento ? (
                    <Text style={styles.codigoFecha}>
                      {firstLine.fecha_vencimiento}
                      {/* Aquí puedes imprimir otras propiedades si es necesario */}
                    </Text>
                  ) : (
                    "-"
                  )}
                </Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.headerCellCabeceraFoot}>
                  Fecha de auditoria de recertificación:
                </Text>
                <Text style={styles.headerCellCabeceraFoot}>
                  {" "}
                  {firstLine && firstLine.fAuditoriaRcertificacion ? (
                    <Text style={styles.codigoFecha}>
                      {firstLine.fAuditoriaRcertificacion}
                      {/* Aquí puedes imprimir otras propiedades si es necesario */}
                    </Text>
                  ) : (
                    "-"
                  )}
                </Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.headerCellCabeceraFoot}>
                  Fecha de revision:
                </Text>
                <Text style={styles.headerCellCabeceraFoot}>
                  {" "}
                  {firstLine && firstLine.frevision ? (
                    <Text style={styles.codigoFecha}>
                      {firstLine.frevision}
                      {/* Aquí puedes imprimir otras propiedades si es necesario */}
                    </Text>
                  ) : (
                    "-"
                  )}
                </Text>
              </View>
            </View>
            <View style={styles.footerLetraDerecha}>
              <Image style={styles.imageLogofoot} src={firma} />
              <Text>Roberto Enrique Montoya Villa</Text>
              <Text>Director Ejecutivo - CEO</Text>
            </View>
            <Text style={styles.version}>F-PS-553 Versión 01</Text>
            <Text
              style={styles.pageNumber}
              render={({ pageNumber, totalPages }) =>
                `Página ${pageNumber} de ${totalPages}`
              }
              fixed
            />
          </Page>
        </Document>
      </PDFViewer>
    </center>
  );
};

export default InformePDF;
