import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import { Facebook, Instagram, Twitter, YouTube } from "@mui/icons-material";
import { Box } from "@mui/material";

export default function Footer() {
  const componentStyle = {
    fontFamily: "Catamaran, sans-serif",
    // other styles
  };
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[200]
            : theme.palette.grey[800],
        p: 6,
      }}
    >
      <Container maxWidth="lg" style={componentStyle}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={4}>
            <Typography
              variant="h6"
              color="text.primary"
              gutterBottom
              style={componentStyle}
            >
              Sobre Nosotros
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              style={componentStyle}
            >
              ICONTEC Todos los derechos reservados
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography
              variant="h6"
              color="text.primary"
              gutterBottom
              style={componentStyle}
            >
              Información de contacto
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              style={componentStyle}
            >
              Dirección:Edificio Elemento, Ac. 26 #69-76 Torre 4 Piso 9
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              style={componentStyle}
            >
              Correo: icontec@icontec.org
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              style={componentStyle}
            >
              Teléfono:: 01-800-0949000
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography
              variant="h6"
              color="text.primary"
              gutterBottom
              style={componentStyle}
            >
              Siguenos!
            </Typography>
            <Link
              href="https://www.facebook.com/ICONTEC.internacional"
              color="inherit"
              style={componentStyle}
            >
              <Facebook color="primary" />
            </Link>
            <Link
              href="https://www.youtube.com/user/icontecinternacional"
              color="inherit"
              sx={{ pl: 1, pr: 1 }}
            >
              <YouTube color="error" />
            </Link>
            <Link
              href="https://www.instagram.com/icontec.oficial/"
              color="inherit"
              sx={{ pl: 1, pr: 1 }}
            >
              <Instagram color="action" />
            </Link>
          </Grid>
        </Grid>
        <Box mt={5}>
          <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            style={componentStyle}
          >
            {"Copyright © "}
            <Link
              color="inherit"
              href="https://www.icontec.org/"
              style={componentStyle}
            >
              https://www.icontec.org/
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}
