import React, { useCallback, useEffect, useMemo, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import { Box, Typography } from "@mui/material";
import axios from "axios";
import Anexos from "../Anexos";

const UnionAnexos = () => {
  return (
    <div>
      <Anexos />
    </div>
  );
};

export default UnionAnexos;
