import React, { useCallback, useEffect, useMemo, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import axios from "axios";

import { useParams } from "react-router-dom";
import { FaFile, FaFileDownload } from "react-icons/fa";
import { Box, IconButton, Tooltip } from "@mui/material";

const Historial = (props) => {
  let { id } = useParams();
  const [modalIsOpen, setIsOpen] = useState(false);
  const handleOpenModalTest = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  const [list1, setList1] = useState([]);
  const urlendpoint =
    "https://middlewarebackprd.azurewebsites.net/api/v1/ConsultaHistorial/" +
    id;
  useEffect(() => {
    axios({
      auth: {
        username: "admin.icontec@icontec.org",
        password: "quAngEraMuSTerGerEDE",
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      method: "get",

      url: urlendpoint,
    })
      .then((Response) => {
        setList1(Response.data);
        console.log(Response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [setList1]);

  const postData = (e) => {
    e.preventDefault(e);
    window.location.href = "/#/InfoCertificados";
  };
  const btnDescarga = useCallback((row) => {
    const url =
      "https://public-api.acreditta.com/credential/record/" +
      row.getValue("id_certificado");

    // Abre la URL en una nueva pestaña
    window.open(url, "_blank");
  });

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        filterVariant: "text",
        enableEditing: false,
      },

      {
        accessorKey: "id_certificado",
        header: "id_certificado",
        filterVariant: "text",
        enableEditing: false,
      },

      {
        accessorKey: "modulo",
        header: "Modulo",
        filterVariant: "text",
      },
      {
        accessorKey: "numero_certificado",
        header: "numero_certificado",
        filterVariant: "text",
      },
      {
        accessorKey: "correo",
        header: "correo",
        filterVariant: "text",
      },
      {
        accessorKey: "envio_correo",
        header: "envio_correo",
        filterVariant: "text",
      },
      {
        accessorKey: "documento",
        header: "documento",
        filterVariant: "text",
      },
      {
        accessorKey: "nombre",
        header: "nombre",
        filterVariant: "text",
      },
      {
        accessorKey: "asistio",
        header: "asistio",
        filterVariant: "text",
      },

      {
        accessorKey: "certificado",
        header: "certificado",
        filterVariant: "text",
      },
      {
        accessorKey: "certificado_ingles",
        header: "certificado_ingles",
        filterVariant: "text",
      },
      {
        accessorKey: "alcance",
        header: "alcance",
        filterVariant: "text",
      },
      {
        accessorKey: "alcance_ingles",
        header: "alcance_ingles",
        filterVariant: "text",
      },
      {
        accessorKey: "intensidad",
        header: "intensidad",
        filterVariant: "text",
      },
      {
        accessorKey: "listado_cursos",
        header: "listado_cursos",
        filterVariant: "text",
      },
      {
        accessorKey: "ciudad",
        header: "ciudad",
        filterVariant: "text",
      },
      {
        accessorKey: "pais",
        header: "pais",
        filterVariant: "text",
      },
      {
        accessorKey: "fecha_certificado",
        header: "fecha_certificado",
        filterVariant: "text",
      },
      {
        accessorKey: "fecha_aprobacion",
        header: "fecha_aprobacion",
        filterVariant: "text",
      },
      {
        accessorKey: "fecha_ultima_modificacion",
        header: "fecha_ultima_modificacion",
        filterVariant: "text",
      },
      {
        accessorKey: "fecha_renovacion",
        header: "fecha_renovacion",
        filterVariant: "text",
      },
      {
        accessorKey: "fecha_vencimiento",
        header: "fecha_vencimiento",
        filterVariant: "text",
      },
      {
        accessorKey: "fecha_impresion",
        header: "fecha_impresion",
        filterVariant: "text",
      },
      {
        accessorKey: "direccion",
        header: "direccion",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_1",
        header: "dato_1",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_2",
        header: "dato_2",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_3",
        header: "dato_3",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_4",
        header: "dato_4",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_5",
        header: "dato_5",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_6",
        header: "dato_6",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_7",
        header: "dato_7",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_8",
        header: "dato_8",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_9",
        header: "dato_9",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_10",
        header: "dato_10",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_11",
        header: "dato_11",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_12",
        header: "dato_12",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_13",
        header: "dato_13",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_14",
        header: "dato_14",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_15",
        header: "dato_15",
        filterVariant: "text",
      },
      {
        accessorKey: "estado",
        header: "Estado",
        filterVariant: "text",
      },
      {
        accessorKey: "emitido",
        header: "Emitido",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_16",
        header: "dato_16",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_17",
        header: "dato_17",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_18",
        header: "dato_18",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_19",
        header: "dato_19",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_20",
        header: "dato_20",
        filterVariant: "text",
      },
    ],
    []
  );

  return (
    <div>
      <button
        className="btn btn-primary btn-lg"
        color="primary"
        onClick={postData}
      >
        Volver
      </button>
      <MaterialReactTable
        displayColumnDefOptions={{
          "mrt-row-actions": {
            muiTableHeadCellProps: {
              align: "center",
            },
            size: 120,
          },
        }}
        columns={columns}
        data={list1}
        editingMode="modal"
        enableColumnOrdering
        enableEditing
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: "flex", gap: "1rem" }}>
            <Tooltip arrow placement="right" title="Descargar">
              <IconButton color="primary" onClick={() => btnDescarga(row)}>
                <FaFileDownload />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      />
    </div>
  );
};

export default Historial;
