import React from "react";
import { Form, Field } from "react-final-form";
import { Select, TextField } from "final-form-material-ui";
import Swal from "sweetalert2";
import axios from "axios";
import { Typography } from "@mui/material";
import { Grid, Paper, Button, MenuItem } from "@mui/material";

function CrearCertificado() {
  const onSubmit = async (values) => {
    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    await sleep(300);
    const fechaActual = new Date(values.fechaCertificado);
    const formatoFecha = fechaActual.toISOString().slice(0, 10);

    axios({
      auth: {
        username: "admin.icontec@icontec.org",
        password: "quAngEraMuSTerGerEDE",
      },
      method: "post",
      url: "https://middlewarebackprd.azurewebsites.net/api/v1/CreaCertificado",
      data: {
        modulo: values.modulo + "",
        numero_certificado: values.numeroCertificado + "",
        correo: values.correo + "",
        envio_correo: values.envioCorreo,
        documento: values.documento + "",
        nombre: values.nombre + "",
        asistio: 0,
        certificado: values.certificado + "",
        certidicado_ingles: values.certidicadoIngles + "",
        alcance: values.alcance + "",
        alcance_ingles: values.alcanceIngles + "",
        intensidad: values.intensidad + "",
        listado_cursos: values.listado_cursos + "",
        regional: values.regional + "",
        ciudad: values.ciudad + "",
        pais: values.pais + "",
        fecha_certificado:
          values.fechaCertificado.toISOString().slice(0, 10) + "",
        fecha_aprobacion:
          values.fecha_aprobacion.toISOString().slice(0, 10) + "",
        fecha_ultima_modificaion:
          values.fechaUltima.toISOString().slice(0, 10) + "",
        fecha_renovacion:
          values.fechaRenovacion.toISOString().slice(0, 10) + "",
        fecha_vencimiento:
          values.fechaVencimiento.toISOString().slice(0, 10) + "",
        fecha_impresion: values.fechaImpresion.toISOString().slice(0, 10) + "",
        direccion: values.direccion + "",
        dato_1: values.dato + "",
        dato_2: values.dato2 + "",
        dato_3: values.dato3 + "",
        dato_4: values.dato4 + "",
        dato_5: values.dato5 + "",
        dato_6: values.dato6 + "",
        dato_7: values.dato7 + "",
        dato_8: values.dato8 + "",
        dato_9: values.dato9 + "",
        dato_10: values.dato10 + "",
        dato_11: values.dato11 + "",
        dato_12: "",
        dato_13: "",
        dato_14: "",
        dato_15: values.dato15,
      },
    })
      .then((Response) => {
        const valida = Response.data;
        console.log(Response.data);
        console.log(valida.resultado);
        Swal.fire({
          title: valida.resultado,
          text: "Haga clic para continuar",
          icon: "success",
          confirmButtonText: "Confirmar",
          showCancelButton: true,
        }).then(function () {
          window.location.href = "/";
          window.location.reload("/");
        });
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: error,
          text: "No se pudo crear",
          icon: "error",
        }).then(function () {
          window.location.reload("/");
        });
      });
  };
  const validate = (values) => {
    const errors = {};

    return errors;
  };
  const tiempoTranscurrido = Date.now();
  const hoy = new Date(tiempoTranscurrido);
  const fechaCreacion = hoy.toISOString();

  return (
    <div style={{ padding: 16, margin: "auto", maxWidth: 900 }}>
      <h1>Crear Certificado</h1>

      <Typography
        style={{ color: "white" }}
        variant="h5"
        align="center"
        component="h5"
        gutterBottom
      >
        CREAR CERTIFICADO
      </Typography>

      <Form
        onSubmit={onSubmit}
        style={{ color: "white" }}
        initialValues={{ asistio: 0, envioCorreo: 0 }}
        validate={validate}
        render={({ handleSubmit, reset, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Paper style={{ padding: 16, background: "white" }}>
              <Grid container alignItems="flex-start" spacing={2}>
                <Grid item xs={6}>
                  <Field
                    fullWidth
                    required
                    name="modulo"
                    component={TextField}
                    type="text"
                    label="modulo"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    fullWidth
                    required
                    name="numeroCertificado"
                    component={TextField}
                    type="text"
                    label="numero certificado"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    required
                    name="correo"
                    component={TextField}
                    type="email"
                    label="Correo"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    fullWidth
                    required
                    name="envioCorreo"
                    component={TextField}
                    type="number"
                    label="envio correo"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    fullWidth
                    required
                    name="documento"
                    component={TextField}
                    type="text"
                    label="documento"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    fullWidth
                    required
                    name="nombre"
                    component={TextField}
                    type="text"
                    label="nombre"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    fullWidth
                    required
                    name="asistio"
                    component={Select}
                    label="Seleccione el si asistio"
                    formControlProps={{ fullWidth: true }}
                  >
                    <MenuItem value="1">Si</MenuItem>
                    <MenuItem value="0">No</MenuItem>
                  </Field>
                </Grid>

                <Grid item xs={6}>
                  <Field
                    fullWidth
                    required
                    name="certificado"
                    component={TextField}
                    type="text"
                    label="certificado"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    fullWidth
                    required
                    name="certificadoIngles"
                    component={TextField}
                    type="text"
                    label="certificado ingles"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    fullWidth
                    required
                    name="alcance"
                    component={TextField}
                    type="text"
                    label="alcance"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    fullWidth
                    required
                    name="alcanceIngles"
                    component={TextField}
                    type="text"
                    label="alcance ingles"
                  />
                </Grid>

                <Grid item xs={6}>
                  <Field
                    fullWidth
                    required
                    name="intensidad"
                    component={TextField}
                    type="number"
                    label="Intensidad"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    required
                    name="listado_cursos"
                    component={TextField}
                    type="text"
                    label="listado_cursos"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    fullWidth
                    required
                    name="regional"
                    component={TextField}
                    type="text"
                    label="Regional"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    fullWidth
                    required
                    name="ciudad"
                    component={TextField}
                    type="text"
                    label="ciudad"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    fullWidth
                    required
                    name="pais"
                    component={TextField}
                    type="text"
                    label="pais"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="h9"
                    align="center"
                    component="h9"
                    gutterBottom
                  >
                    Fecha Certificado
                  </Typography>
                  <Field
                    fullWidth
                    required
                    name="fechaCertificado"
                    component={TextField}
                    type="date"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="h9"
                    align="fecha"
                    component="h9"
                    gutterBottom
                  >
                    Fecha Aprobacion
                  </Typography>
                  <Field
                    fullWidth
                    required
                    name="fechaAprobacion"
                    component={TextField}
                    type="date"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="h9"
                    align="center"
                    component="h9"
                    gutterBottom
                  >
                    Fecha Ultima Modificacion
                  </Typography>
                  <Field
                    fullWidth
                    required
                    name="fechaUltima"
                    component={TextField}
                    type="date"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="h9"
                    align="center"
                    component="h9"
                    gutterBottom
                  >
                    Fecha Renovacion
                  </Typography>
                  <Field
                    fullWidth
                    required
                    name="fechaRenovacion"
                    component={TextField}
                    type="date"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="h9"
                    align="center"
                    component="h9"
                    gutterBottom
                  >
                    Fecha Vencimiento
                  </Typography>
                  <Field
                    fullWidth
                    required
                    name="fechaVencimiento"
                    component={TextField}
                    type="date"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="h9"
                    align="center"
                    component="h9"
                    gutterBottom
                  >
                    fecha Impresion
                  </Typography>
                  <Field
                    fullWidth
                    required
                    name="fechaImpresion"
                    component={TextField}
                    type="date"
                  />
                </Grid>

                <Grid item xs={6}>
                  <br></br>
                  <Field
                    fullWidth
                    required
                    name="dato15"
                    component={Select}
                    label="Seleccione el Legado"
                    formControlProps={{ fullWidth: true }}
                  >
                    <MenuItem value="SIA">SIA</MenuItem>
                    <MenuItem value="SIVOL">SIVOL</MenuItem>
                    <MenuItem value="CONTREEBUTE">CONTREEBUTE</MenuItem>
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    required
                    name="direccion"
                    component={TextField}
                    label="Dirección"
                    type="text"
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    fullWidth
                    required
                    name="dato"
                    component={TextField}
                    label="Dato"
                    type="text"
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    fullWidth
                    required
                    name="dato2"
                    component={TextField}
                    label="Dato2"
                    type="text"
                  />
                </Grid>

                <Grid item xs={4}>
                  <Field
                    fullWidth
                    required
                    name="dato3"
                    component={TextField}
                    label="Dato3"
                    type="text"
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    fullWidth
                    required
                    name="dato4"
                    component={TextField}
                    label="Dato4"
                    type="text"
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    fullWidth
                    required
                    name="dato5"
                    component={TextField}
                    label="Dato5"
                    type="text"
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    fullWidth
                    required
                    name="dato6"
                    component={TextField}
                    label="Dato6"
                    type="text"
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    fullWidth
                    required
                    name="dato7"
                    component={TextField}
                    label="Dato7"
                    type="text"
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    fullWidth
                    required
                    name="dato8"
                    component={TextField}
                    label="Dato8"
                    type="text"
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    fullWidth
                    required
                    name="dato9"
                    component={TextField}
                    label="Dato9"
                    type="text"
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    fullWidth
                    required
                    name="dato10"
                    component={TextField}
                    label="Dato10"
                    type="text"
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    fullWidth
                    required
                    name="dato11"
                    component={TextField}
                    label="Dato11"
                    type="text"
                  />
                </Grid>
              </Grid>

              <center>
                <Grid item style={{ marginTop: 16 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={submitting}
                    label="siguiente"
                  >
                    ENVIAR
                  </Button>
                </Grid>
              </center>
            </Paper>
          </form>
        )}
      />
    </div>
  );
}

export default CrearCertificado;
