import React, { useState } from "react";
import PropTypes from "prop-types";
import { NavLink as RouterLink } from "react-router-dom";
import { Box, List, ListItemText, Collapse } from "@mui/material";
import { StyledNavItem, StyledNavItemIcon } from "./styles";

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
          <NavItem key={item.title} item={item} />
        ))}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, icon, subMenu } = item;
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <StyledNavItem
        component={subMenu ? "div" : RouterLink}
        to={subMenu ? "" : item.path}
        onClick={subMenu && handleClick}
      >
        <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
        <ListItemText disableTypography primary={title} />
      </StyledNavItem>

      {subMenu && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {subMenu.map((subItem) => (
              <NavItem key={subItem.title} item={subItem} />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
}
