import React, { useEffect, useMemo, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import { Box } from "@mui/material";
import axios from "axios";

const InfoPlantillas = () => {
  const [list1, setList1] = useState([]);
  const urlendpoint =
    "https://middlewarebackprd.azurewebsites.net/api/v1/ObtenerPlantillas";
  useEffect(() => {
    axios({
      auth: {
        username: "admin.icontec@icontec.org",
        password: "quAngEraMuSTerGerEDE",
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      method: "get",

      url: urlendpoint,
    })
      .then((Response) => {
        setList1(Response.data);
        console.log(Response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [setList1]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Nombre",
        enableColumnOrdering: false,
        enableEditing: true,
        enableSorting: false,
        size: 80,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <img
              alt=""
              height={30}
              src={row.original.imageUrl}
              loading="lazy"
              style={{ borderRadius: "20%" }}
            />
            {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
            <span>{renderedCellValue}</span>
          </Box>
        ),
      },
      {
        accessorKey: "credentialTemplateId",
        header: "credentialTemplateId",
        enableColumnOrdering: false,
        enableEditing: true,
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: "imageUrl",
        header: "UrlImagen",
        enableColumnOrdering: false,
        enableEditing: true,
        enableSorting: false,
        size: 80,
      },

      {
        accessorKey: "status",
        header: "Estado",
        enableColumnOrdering: false,
        enableEditing: true,
        enableSorting: false,
        size: 80,
      },
    ],
    []
  );

  return (
    <div>
      <MaterialReactTable
        displayColumnDefOptions={{
          "mrt-row-actions": {
            muiTableHeadCellProps: {
              align: "center",
            },
            size: 120,
          },
        }}
        columns={columns}
        data={list1}
        editingMode="modal"
        enableColumnOrdering
        initialState={{ columnVisibility: { imageUrl: false } }}
        renderDetailPanel={({ row }) => (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            {row.original.imageUrl == "" ? (
              <h>No se adjunto imagen</h>
            ) : (
              <img
                alt="avatar"
                height={200}
                src={row.original.imageUrl}
                loading="lazy"
              />
            )}
          </Box>
        )}
      />
    </div>
  );
};

export default InfoPlantillas;
