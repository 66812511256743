import React, { useCallback, useEffect, useMemo, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import { Box, IconButton, Tooltip } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import axios from "axios";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";

const InfoCopias = () => {
  const cookie = new Cookies();

  const [list1, setList1] = useState([]);
  const urlendpoint =
    "https://middlewarebackprd.azurewebsites.net/api/v1/ObtenerCopias";
  useEffect(() => {
    axios({
      auth: {
        username: "admin.icontec@icontec.org",
        password: "quAngEraMuSTerGerEDE",
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      method: "get",

      url: urlendpoint,
    })
      .then((Response) => {
        setList1(Response.data);
        console.log(Response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [setList1]);

  const [tableData, setTableData] = useState(() => list1);
  const [validationErrors, setValidationErrors] = useState({});

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        enableColumnOrdering: false,
        enableEditing: false,
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: "filial",
        header: "Filial",
        enableColumnOrdering: false,
        enableEditing: true,
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: "envio",
        header: "Envio",
        enableColumnOrdering: false,
        enableEditing: true,
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: "cargoEnvio",
        header: "Cargo Envio",
        enableColumnOrdering: false,
        enableEditing: true,
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: "correoEnvio",
        header: "correoEnvio",
        enableColumnOrdering: false,
        enableEditing: true,
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: "envio",
        header: "Envio",
        enableColumnOrdering: false,
        enableEditing: true,
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: "copia",
        header: "Copia",
        enableColumnOrdering: false,
        enableEditing: true,
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: "cargoCopia",
        header: "cargoCopia",
        enableColumnOrdering: false,
        enableEditing: true,
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: "correoCopia",
        header: "correoCopia",
        enableColumnOrdering: false,
        enableEditing: true,
        enableSorting: false,
        size: 80,
      },
    ],
    []
  );
  const postData = (e) => {
    window.location.href = "/#/CreaCopias";
  };
  return (
    <div>
      <button
        style={{ fontFamily: "Catamaran, sans-serif" }}
        className="btn btn-primary btn-lg"
        color="primary"
        onClick={postData}
      >
        Agregar
      </button>
      <div className="test">
        <MaterialReactTable
          displayColumnDefOptions={{
            "mrt-row-actions": {
              muiTableHeadCellProps: {
                align: "center",
              },
              size: 120,
            },
          }}
          columns={columns}
          data={list1}
          editingMode="modal"
          enableColumnOrdering
          enableEditing
        />
      </div>
    </div>
  );
};

export default InfoCopias;
