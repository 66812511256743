import { Helmet } from "react-helmet-async";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import { Button, Typography, Container, Box } from "@mui/material";
import logo from "../../img/lgo.png";
// ----------------------------------------------------------------------

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Error() {
  return (
    <>
      <Helmet>
        <title> Error</title>
      </Helmet>

      <Container>
        <StyledContent sx={{ textAlign: "center", alignItems: "center" }}>
          <Typography sx={{ color: "text.secondary" }}> </Typography>
          <Box
            component="img"
            src={logo}
            sx={{ height: 260, mx: "auto", my: { xs: 5, sm: 10 } }}
          />
          <Typography variant="h3" paragraph>
            Lo siento no hemos podido validar este certificado
          </Typography>

          <Button
            to="/"
            size="large"
            variant="contained"
            component={RouterLink}
          >
            Volver al Inicio
          </Button>
        </StyledContent>
      </Container>
    </>
  );
}
