import React, { useMemo, useState, useCallback } from "react";
import ReCAPTCHA from "react-google-recaptcha"; // Importa el componente de reCAPTCHA
import img from "../img/LOGO-Icontec-2019_hor_pos.png";
import axios from "axios";
import {
  FaBookReader,
  FaFilePdf,
  FaSearchPlus,
  FaUpload,
} from "react-icons/fa";
import { MaterialReactTable } from "material-react-table";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { MRT_Localization_ES } from "material-react-table/locales/es";

import FooterIcontec from "./Obtener/Footer";
import HuellaNav from "./HeaderHuella";
import Swal from "sweetalert2";
function Formulario() {
  const [captchaValue, setCaptchaValue] = useState(""); // Estado para almacenar el valor del reCAPTCHA
  const [list, setList] = useState([]);
  const [list1, setList1] = useState([]);
  const [postCertificado, setPostCertificado] = useState([]);
  const [postDocumento, setPostDocumento] = useState([]);
  const [postEmpresa, setPostEmpresa] = useState([]);
  const columns = useMemo(
    () => [
      {
        accessorKey: "nombre",
        header: "Nombre",
        filterVariant: "text",
      },
      {
        accessorKey: "certificado",
        header: "Nombre Certificado",
        filterVariant: "text",
      },
      {
        accessorKey: "numero_certificado",
        header: "Numero certificado",
        filterVariant: "text",
      },
      {
        accessorKey: "pais",
        header: "Pais",
        filterVariant: "text",
      },
      {
        accessorKey: "ciudad",
        header: "Ciudad",
        filterVariant: "text",
      },
      {
        accessorKey: "direccion",
        header: "Direccion",
        filterVariant: "text",
      },
      {
        accessorKey: "estado",
        header: "Estado",
        filterVariant: "text",
      },
      {
        accessorKey: "id_certificado",
        header: "id_certificado",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_15",
        header: "dato_15",
        filterVariant: "text",
      },
    ],
    []
  );
  const columnsBpm = useMemo(
    () => [
      {
        accessorKey: "nombre",
        header: "Nombre",
        filterVariant: "text",
      },
      {
        accessorKey: "certificado",
        header: "Nombre Certificado",
        filterVariant: "text",
      },
      {
        accessorKey: "id_certificado_externo",
        header: "Numero certificado",
        filterVariant: "text",
      },
      {
        accessorKey: "pais",
        header: "Pais",
        filterVariant: "text",
      },
      {
        accessorKey: "ciudad",
        header: "Ciudad",
        filterVariant: "text",
      },
      {
        accessorKey: "direccion",
        header: "Direccion",
        filterVariant: "text",
      },
      {
        accessorKey: "estado",
        header: "Estado",
        filterVariant: "text",
      },
      {
        accessorKey: "id_certificado",
        header: "id_certificado",
        filterVariant: "text",
      },
      {
        accessorKey: "dato_15",
        header: "dato_15",
        filterVariant: "text",
      },
    ],
    []
  );
  const Acceder = (e) => {
    window.location.href = "/#/Login";
  };

  const postData = (e) => {
    e.preventDefault();
    if (!captchaValue) {
      // Si el reCAPTCHA no se ha completado, muestra un mensaje de error
      window.alert("Por favor, completa el reCAPTCHA");
      return;
    }
    Swal.fire({
      title: "Consultando...",
      text: "Espere un momento mientras carga la información.",
      showConfirmButton: false,
      allowOutsideClick: false,
    });

    const authConfig = {
      auth: {
        username: "admin.icontec@icontec.org",
        password: "quAngEraMuSTerGerEDE",
      },
    };

    const requestData = {
      certificado: postCertificado + "",
      documento: postDocumento + "",
      Empresa: postEmpresa + "",
    };

    const promises = [];

    const firstRequest = axios({
      ...authConfig,
      method: "post",
      url: "https://middlewarebackprd.azurewebsites.net/api/v1/ObtenerCertificado",
      data: requestData,
    });

    promises.push(firstRequest);

    const secondRequest = axios({
      ...authConfig,
      method: "post",
      url: "https://middlewarebackprd.azurewebsites.net/api/v1/ObtenerBPM",
      data: requestData,
    });

    promises.push(secondRequest);

    Promise.all(promises)
      .then((responses) => {
        const certificadoResponse = responses[0];
        const bpmResponse = responses[1];

        setList(bpmResponse.data);
        setList1(certificadoResponse.data);

        Swal.close();

        if (!bpmResponse.data.length && !certificadoResponse.data.length) {
          Swal.fire({
            title: "Sin Información.",
            text: "No existe información para los datos ingresados",
            icon: "info",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((error) => {
        Swal.close();
        window.alert("Ocurrió un error. Verifique los campos.");
        console.log(error);
        window.location.reload("/");
      });
  };

  const btnInforme = useCallback((row) => {
    window.location.href = "/#/Informe/" + row.getValue("numero_certificado");
  });
  const Recarga = (e) => {
    window.location.reload("/");
  };
  const btnAnexosB = useCallback((row) => {
    const url =
      "http://qa.icontec.org:8027/#/" + row.getValue("numero_certificado");

    window.open(url, "_blank");
  });
  const btnAnexosBPM = useCallback((row) => {
    const url =
      "http://qa.icontec.org:8027/#/" + row.getValue("id_certificado_externo");
    window.open(url, "_blank");
  });
  const componentStyle = {
    fontFamily: "Catamaran, sans-serif",
    // other styles
  };
  return (
    <div>
      <HuellaNav />
      <br></br>
      <div>
        <div className="fondo-marca-agua">
          <link
            href="//maxcdn.bootstrapcdn.com/bootstrap/4.1.1/css/bootstrap.min.css"
            rel="stylesheet"
            id="bootstrap-css"
          />

          <div className="container">
            <center>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <h1
                  style={{
                    color: "#002E5D",
                    marginRight: "7px",
                    fontFamily: "Catamaran, sans-serif",
                  }}
                >
                  Consulta el estado
                </h1>
                <h1
                  style={{
                    color: "#0085CA",
                    fontFamily: "Catamaran, sans-serif",
                  }}
                >
                  de un certificado
                </h1>
              </div>

              <br></br>
              <font color="#002E5D" style={componentStyle}>
                <h6>
                  Si no encuentra el certificado o tiene alguna duda al
                  respecto, le agradecemos que se comunique con nosotros a
                  través de nuestros canales de servicio al cliente:
                  cliente@icontec.org o llamando a la línea de atención #426 o
                  al 01 8000 94 9000.
                </h6>
                <br></br>
              </font>
            </center>
            {list == "" ? (
              <center>
                <form className="user">
                  <div className="form-group row">
                    <div className="col-sm-4 mb-3 mb-sm-0">
                      <font color="#002E5D" style={componentStyle}>
                        {" "}
                        <strong> NIT O NÚMERO DE DOCUMENTO:</strong>
                      </font>
                      <input
                        type="text"
                        name="text"
                        className="form-control"
                        placeholder="Sin punto ni guiones"
                        onChange={(e) => setPostDocumento(e.target.value)}
                      />
                    </div>
                    <div className="col-sm-4">
                      <font color="#002E5D" style={componentStyle}>
                        {" "}
                        <strong> EMPRESA O NOMBRE:</strong>{" "}
                      </font>
                      <input
                        type="text"
                        name="text"
                        className="form-control"
                        placeholder="Nombre"
                        onChange={(e) => setPostEmpresa(e.target.value)}
                      />
                    </div>
                    <div className="col-sm-4">
                      <font color="#002E5D" style={componentStyle}>
                        {" "}
                        <strong>NÚMERO DE CERTIFICADO:</strong>
                      </font>
                      <input
                        type="text"
                        name="text"
                        className="form-control"
                        placeholder="#"
                        onChange={(e) => setPostCertificado(e.target.value)}
                      />
                    </div>

                    <hr />
                  </div>
                  <ReCAPTCHA // Agrega el componente de reCAPTCHA
                    sitekey="6Le5kXgpAAAAAK5I7r1lrf2sFkklwe5jYJKjdflA"
                    onChange={setCaptchaValue}
                  />
                  <center>
                    <button
                      className="btn btn-primary btn-lg"
                      color="primary"
                      onClick={postData}
                    >
                      Consultar
                    </button>
                  </center>
                </form>
              </center>
            ) : (
              <h></h>
            )}
            {list != "" || list1 != "" ? (
              <div>
                <button
                  className="btn btn-secondary btn-lg"
                  color="primary"
                  onClick={Recarga}
                >
                  Nueva Consulta
                </button>
                <MaterialReactTable
                  localization={MRT_Localization_ES}
                  enableStickyHeader
                  columns={columns}
                  data={list1}
                  initialState={{
                    showColumnFilters: true,
                    columnVisibility: { id_certificado: false, dato_15: false },
                  }}
                  enableRowActions
                  editingMode="modal" //default
                  enableEditing
                  enableStickyFooter
                  onEditingRowSave={btnInforme}
                  renderRowActions={({ row, table }) => (
                    <Box sx={{ display: "flex", gap: "1rem" }}>
                      {row.getValue("dato_15") == "Send" ? (
                        <Tooltip arrow placement="right" title="Ver Anexo">
                          <IconButton
                            color="error"
                            onClick={() => btnInforme(row)}
                          >
                            <FaFilePdf color="#0085CA" />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <h></h>
                      )}
                      <Tooltip
                        arrow
                        placement="right"
                        title="Detalle del certificado"
                      >
                        <IconButton
                          color="secondary"
                          onClick={() => btnAnexosB(row)}
                        >
                          <FaSearchPlus />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                  renderDetailPanel={({ row }) => (
                    <Box
                      sx={{
                        display: "grid",
                        margin: "auto",
                        gridTemplateColumns: "1fr 1fr",
                        width: "100%",
                      }}
                    >
                      <Typography>
                        <b>Alcance:</b> {row.original.alcance}
                      </Typography>
                      <Typography>
                        <b>Certificado:</b> {row.original.numero_certificado}
                      </Typography>
                    </Box>
                  )}
                />
                <MaterialReactTable
                  title="indicadores"
                  localization={MRT_Localization_ES}
                  enableStickyHeader
                  columns={columnsBpm}
                  data={list}
                  initialState={{
                    showColumnFilters: true,
                    columnVisibility: { id_certificado: false, dato_15: false },
                  }}
                  enableRowActions
                  editingMode="modal" //default
                  enableEditing
                  enableStickyFooter
                  onEditingRowSave={btnInforme}
                  renderRowActions={({ row, table }) => (
                    <Box sx={{ display: "flex", gap: "1rem" }}>
                      {row.getValue("dato_15") == "Send" ? (
                        <Tooltip arrow placement="right" title="Ver Anexo">
                          <IconButton
                            color="error"
                            onClick={() => btnInforme(row)}
                          >
                            <FaFilePdf color="#0085CA" />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <h></h>
                      )}
                      <Tooltip
                        arrow
                        placement="right"
                        title="Detalle del certificado"
                      >
                        <IconButton
                          color="secondary"
                          onClick={() => btnAnexosBPM(row)}
                        >
                          <FaSearchPlus />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                  renderDetailPanel={({ row }) => (
                    <Box
                      sx={{
                        display: "grid",
                        margin: "auto",
                        gridTemplateColumns: "1fr 1fr",
                        width: "100%",
                      }}
                    >
                      <Typography>
                        <b>Alcance:</b> {row.original.alcance}
                      </Typography>
                      <Typography>
                        <b>Certificado:</b> {row.original.numero_certificado}
                      </Typography>
                    </Box>
                  )}
                />
              </div>
            ) : (
              <h></h>
            )}
            <br></br> <br></br>
            <br></br> <br></br> <br></br> <br></br>
          </div>
        </div>
      </div>
      <FooterIcontec />
    </div>
  );
}
export default Formulario;
