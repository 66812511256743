import React, { useState, useEffect } from "react";
import {
  PDFViewer,
  Document,
  Page,
  Text,
  PDFDownloadLink,
  Font,
  StyleSheet,
  View,
  Image,
} from "@react-pdf/renderer";
import axios from "axios";
import { useParams } from "react-router-dom";
import header from "../img/LOGO-Icontec-2019_hor_pos.png";
import fondoAnexo from "../img/CERTIFICADO-Icontec-Anexos.png";
import firma from "../img/firma.png";

const InformePDFSinFondo = () => {
  let { id } = useParams();
  Font.register({
    family: "Lato",
    src: "https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjx4wWw.ttf",
  });
  const styles = StyleSheet.create({
    page: {
      flexDirection: "row",
      backgroundColor: "#ffffff",
      padding: 30,
    },
    background: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      opacity: 0.9, // Ajusta la opacidad según sea necesario
    },
    column: {
      width: "50%",
      paddingRight: 10,
    },
    text: {
      fontSize: 12,
      marginBottom: 10,
    },
    body: {
      paddingTop: 55,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    title: {
      fontSize: 18,
      textAlign: "center",
      fontFamily: "Lato",
    },
    author: {
      fontSize: 12,
      margin: 12,
    },
    subtitleTitle: {
      fontSize: 14,
      margin: 12,
      textAlign: "center",
      fontFamily: "Lato",
    },

    subtitle: {
      fontSize: 18,
      margin: 12,
    },
    text: {
      margin: 12,
      fontSize: 12,
      textAlign: "center",
    },

    header: {
      fontSize: 12,
      marginBottom: 20,
      textAlign: "center",
      color: "grey",
      fontFamily: "Lato",
    },

    footerImagenDerecha: {
      position: "absolute",
      bottom: "auto",
      left: 10,
      right: 50,
      textAlign: "right",
      fontSize: 10,
    },

    footerLetraDerecha: {
      position: "auto",
      bottom: 90,
      left: 10,
      right: 40,
      textAlign: "right",
      fontSize: 12,
      fontFamily: "Lato",
    },
    headerCellprimeras: {
      width: "25%",
      fontSize: 9,
      color: "#2A2A2A",
      border: 0.5,
      wrap: "wrap",
      textAlign: "center",
      fontFamily: "Lato",
      padding: 5,
    },
    headerCellDos: {
      width: "50%",
      fontSize: 9,
      color: "#2A2A2A",
      border: 0.5,
      wrap: "wrap",
      textAlign: "justify",
      fontFamily: "Lato",
      padding: 5, // Añadido padding
    },
    headerCellCabeceraFoot: {
      width: "45%",
      fontSize: 11,
      textAlign: "left",
      fontFamily: "Lato",
      bottom: "auto",
    },
    headerCellCabecera: {
      width: "45%",
      fontSize: 10,
      textAlign: "center",
      fontFamily: "Lato",
      border: 0.5,
    },
    table: {
      display: "table",
      width: "100%",

      wrap: "wrap",
    },
    tableRow: {
      flexDirection: "row",

      width: "100%",
      wrap: "wrap",
    },
    headerCell: {
      width: "40%",
      fontSize: 9,

      color: "#2A2A2A",
      border: 0.5,
      wrap: "wrap",
      textAlign: "justify",
      fontFamily: "Lato",
      padding: 5,
    },
    headerCellFoot: {
      width: "45%",
      fontSize: 7,
      bottom: -450,
      color: "#2A2A2A",
      border: 0.5,
      wrap: "wrap",
      textAlign: "left",
      fontFamily: "Lato",
    },
    tableCol: {
      width: "50%", // O el ancho que desees para las celdas de datos

      wrap: "wrap",
    },
    tableCell: {
      margin: "auto",
      marginTop: 5,

      fontSize: 10,
      wrap: "wrap",
      fontFamily: "Lato",
    },
    imageLogo: {
      width: 170,
      height: 70,
      marginRight: 20,

      bottom: -20,
      left: 20,
      right: 20,
    },
    header: {
      position: "absolute",
      top: 10,
      left: 10,
      right: 10,
      textAlign: "left",
      fontSize: 12,
      fontFamily: "Lato",
    },
    imageLogofoot: {
      width: 150,
      height: 70,
      marginRight: 20,
      bottom: -20,
      left: 370,
      right: 20,
    },
    fechas: {
      fontSize: 12,
      color: "#000000",
    },
    codigoFecha: {
      fontSize: 10,
    },
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 20,
      textAlign: "right",
      color: "grey",
    },
    version: {
      position: "absolute",
      fontSize: 12,
      bottom: 45,
      left: 0,
      right: 20,
      textAlign: "right",
      color: "grey",
    },
  });
  const [data, setData] = useState([]);
  const [firstLine, setFirstLine] = useState(null);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://middlewarebackprd.azurewebsites.net/api/v1/ObtenerAnexos/" +
          id,
        {
          auth: {
            username: "admin.icontec@icontec.org",
            password: "quAngEraMuSTerGerEDE",
          },

          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setData(response.data);
      if (response.data && response.data.length > 0) {
        setFirstLine(response.data[0]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <center>
      <PDFViewer width={"100%"} height="1700">
        <Document>
          <Page style={styles.body}>
            <Text style={styles.title}>
              {" "}
              {firstLine && (
                <Text style={styles.title}>
                  {firstLine.nombre}
                  {/* Print other properties as needed */}
                </Text>
              )}
            </Text>
            <Text style={styles.subtitleTitle}>
              Anexo A Certificado
              {firstLine && (
                <Text style={styles.subtitleTitle}>
                  {" " + firstLine.numero_certificado + " / "}
                  {/* Print other properties as needed */}
                </Text>
              )}
              Certificate code
              {firstLine && (
                <Text style={styles.subtitleTitle}>
                  {" CO-" + firstLine.numero_certificado}
                  {/* Print other properties as needed */}
                </Text>
              )}
            </Text>
            <Text style={styles.author}></Text>
            <View>
              <View style={styles.tableRow}>
                <Text style={styles.headerCellprimeras}>
                  Sede y Direcciones de los sitios permanentes diferentes a la
                  sede principal
                </Text>
                <Text style={styles.headerCellprimeras}>Localización</Text>
                <Text style={styles.headerCellDos}>
                  Actividades del alcance del sistema de gestión desarrolladas
                  en este sitio
                </Text>
              </View>
              {data.map((item) => {
                const fecha = new Date(item.fecha);

                return (
                  <View style={styles.tableRow}>
                    <Text style={styles.headerCellprimeras}>{item.dato_1}</Text>
                    <Text style={styles.headerCellprimeras}>{item.dato_2}</Text>
                    <Text style={styles.headerCellDos}>{item.dato_3}</Text>
                  </View>
                );
              })}
            </View>
            <Text style={{ marginBottom: 12 }}></Text>
            <Text style={{ marginBottom: 12 }}></Text>
            <View>
              <View style={styles.tableRow}>
                <Text style={styles.headerCellCabeceraFoot}>
                  Fecha Otorgamiento
                </Text>
                <Text style={styles.headerCellCabeceraFoot}>
                  {" "}
                  {firstLine && firstLine.fecha_certificado ? (
                    <Text style={styles.codigoFecha}>
                      {firstLine.fecha_certificado}
                      {/* Aquí puedes imprimir otras propiedades si es necesario */}
                    </Text>
                  ) : (
                    "-"
                  )}
                </Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.headerCellCabeceraFoot}>
                  Fecha Vencimiento ciclo previo:
                </Text>
                <Text style={styles.headerCellCabeceraFoot}>
                  {" "}
                  {firstLine && firstLine.datos3 ? (
                    <Text style={styles.codigoFecha}>
                      {firstLine.datos3}
                      {/* Aquí puedes imprimir otras propiedades si es necesario */}
                    </Text>
                  ) : (
                    "-"
                  )}
                </Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.headerCellCabeceraFoot}>
                  Fecha Inicio del ciclo actual de certificación:
                </Text>
                <Text style={styles.headerCellCabeceraFoot}>
                  {" "}
                  {firstLine && firstLine.fInicioCActualCert ? (
                    <Text style={styles.codigoFecha}>
                      {firstLine.fInicioCActualCert}
                      {/* Aquí puedes imprimir otras propiedades si es necesario */}
                    </Text>
                  ) : (
                    "-"
                  )}
                </Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.headerCellCabeceraFoot}>
                  Fecha Vencimiento ciclo actual:
                </Text>
                <Text style={styles.headerCellCabeceraFoot}>
                  {" "}
                  {firstLine && firstLine.fecha_vencimiento ? (
                    <Text style={styles.codigoFecha}>
                      {firstLine.fecha_vencimiento}
                      {/* Aquí puedes imprimir otras propiedades si es necesario */}
                    </Text>
                  ) : (
                    "-"
                  )}
                </Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.headerCellCabeceraFoot}>
                  Fecha de auditoria de recertificación:
                </Text>
                <Text style={styles.headerCellCabeceraFoot}>
                  {" "}
                  {firstLine && firstLine.fAuditoriaRcertificacion ? (
                    <Text style={styles.codigoFecha}>
                      {firstLine.fAuditoriaRcertificacion}
                      {/* Aquí puedes imprimir otras propiedades si es necesario */}
                    </Text>
                  ) : (
                    "-"
                  )}
                </Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.headerCellCabeceraFoot}>
                  Fecha de revision:
                </Text>
                <Text style={styles.headerCellCabeceraFoot}>
                  {" "}
                  {firstLine && firstLine.frevision ? (
                    <Text style={styles.codigoFecha}>
                      {firstLine.frevision}
                      {/* Aquí puedes imprimir otras propiedades si es necesario */}
                    </Text>
                  ) : (
                    "-"
                  )}
                </Text>
              </View>
            </View>
            <View style={styles.footerImagenDerecha} fixed></View>
            <View style={styles.footerLetraDerecha}>
              <Image
                style={styles.imageLogofoot}
                source={{
                  uri: firma,
                }}
              />
              {/* Este View agrega un espacio (marginBottom) después de la imagen */}
              <Text>Roberto Enrique Montoya Villa</Text>
              <Text>Director Ejecutivo - CEO</Text>
            </View>
            <Text style={styles.version}>F-PS-553 Versión 01</Text>
            <Text
              style={styles.pageNumber}
              render={({ pageNumber, totalPages }) =>
                `Página ${pageNumber} de ${totalPages}`
              }
              fixed
            />
          </Page>
        </Document>
      </PDFViewer>
    </center>
  );
};

export default InformePDFSinFondo;
