import React, { useCallback, useEffect, useMemo, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { Add, Delete, Edit, Search, Update } from "@mui/icons-material";
import axios from "axios";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";

const InfoContreebute = () => {
  const cookie = new Cookies();
  const postData = (e) => {
    window.location.href = "/#/CrearContreebute";
  };
  const [list1, setList1] = useState([]);
  const urlendpoint =
    "https://middlewarebackprd.azurewebsites.net/api/v1/VerContreebute/2022/2023-08-23";
  useEffect(() => {
    axios({
      auth: {
        username: "admin.icontec@icontec.org",
        password: "quAngEraMuSTerGerEDE",
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      method: "get",

      url: urlendpoint,
    })
      .then((Response) => {
        setList1(Response.data);
        console.log(Response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [setList1]);

  const [tableData, setTableData] = useState(() => list1);
  const [validationErrors, setValidationErrors] = useState({});
  const fecha = new Date();
  const añoActual = fecha.getFullYear();
  const añoAnterior = añoActual - 1;
  const fechaActual = new Date();
  const formatoFecha = fechaActual.toISOString().slice(0, 10);

  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    if (!Object.keys(validationErrors).length) {
      tableData[row.index] = values;
      setTableData([...tableData]);
      exitEditingMode();
    }
  };

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  const handleDeleteRow = useCallback();

  const columns = useMemo(
    () => [
      {
        accessorKey: "RazonSocial",
        header: "Razon Social",
        enableColumnOrdering: false,
        enableEditing: true,
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: "NIT",
        header: "NIT",
        enableColumnOrdering: false,
        enableEditing: true,
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: "DireccionSedePrincipal",
        header: "Dirección Sede Principal",
        enableColumnOrdering: false,
        enableEditing: true,
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: "Ciudad",
        header: "Ciudad",
        enableColumnOrdering: false,
        enableEditing: true,
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: "Pais",
        header: "Pais",
        enableColumnOrdering: false,
        enableEditing: true,
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: "CorreoEmpresa",
        header: "Correo Empresa",
        enableColumnOrdering: false,
        enableEditing: true,
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: "TipoEvaluacion",
        header: "Tipo Evaluacion",
        enableColumnOrdering: false,
        enableEditing: true,
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: "DireccionamientoEstrategico",
        header: "DireccionamientoEstrategico",
        enableColumnOrdering: false,
        enableEditing: true,
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: "DimensionAmbiental",
        header: "DimensionAmbiental",
        enableColumnOrdering: false,
        enableEditing: true,
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: "DimensionEconomica",
        header: "DimensionEconomica",
        enableColumnOrdering: false,
        enableEditing: true,
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: "DimensionSocial",
        header: "DimensionSocial",
        enableColumnOrdering: false,
        enableEditing: true,
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: "ResultadoTotal",
        header: "ResultadoTotal",
        enableColumnOrdering: false,
        enableEditing: true,
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: "CategoriaAlcanzada",
        header: "CategoriaAlcanzada",
        enableColumnOrdering: false,
        enableEditing: true,
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: "NumeroCertificado",
        header: "NumeroCertificado",
        enableColumnOrdering: false,
        enableEditing: true,
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: "NombreAuditor",
        header: "NombreAuditor",
        enableColumnOrdering: false,
        enableEditing: true,
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: "FechaOtorgamiento",
        header: "FechaOtorgamiento",
        enableColumnOrdering: false,
        enableEditing: true,
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: "FechaVencimiento",
        header: "FechaVencimiento",
        enableColumnOrdering: false,
        enableEditing: true,
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: "FechaAprobacion",
        header: "FechaAprobacion",
        enableColumnOrdering: false,
        enableEditing: true,
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: "FechaActualizacion",
        header: "FechaActualizacion",
        enableColumnOrdering: false,
        enableEditing: true,
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: "NombreImagenSello",
        header: "NombreImagenSello",
        enableColumnOrdering: false,
        enableEditing: true,
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: "URLImagenSello",
        header: "URLImagenSello",
        enableColumnOrdering: false,
        enableEditing: true,
        enableSorting: false,
        size: 80,
      },
    ],
    []
  );

  return (
    <div>
      <div className="test">
        <button
          className="btn btn-primary btn-lg"
          color="primary"
          onClick={postData}
        >
          Crear Contreebute
        </button>
      </div>
      <MaterialReactTable
        displayColumnDefOptions={{
          "mrt-row-actions": {
            muiTableHeadCellProps: {
              align: "center",
            },
            size: 120,
          },
        }}
        columns={columns}
        data={list1}
        editingMode="modal"
        enableColumnOrdering
      />
    </div>
  );
};

export default InfoContreebute;
