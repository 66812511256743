import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

// routes
import "./css/sb-admin-2.min.css";
import "./css/App.css";

// theme

import ThemeProvider from "./theme";
// components

import ScrollToTop from "./components/scroll-to-top";
import Rutas from "./routes";
import ObtenerQR from "./pages/Obtener/ObtenerQR";

// ----------------------------------------------------------------------

export default function App() {
  return (
    <HelmetProvider>
      <HashRouter>
        <ThemeProvider>
          <ScrollToTop />

          <Rutas />
        </ThemeProvider>
      </HashRouter>
    </HelmetProvider>
  );
}
