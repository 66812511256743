import { ExitToApp } from "@mui/icons-material";
import React from "react";
import Cookies from "universal-cookie";

function CerrarSesion() {
  const cookie = new Cookies();

  const cerrarSecion = () => {
    cookie.remove("nombre", { path: "/" });
    cookie.remove("identificacion", { path: "/" });
    cookie.remove("ut", { path: "/" });
    cookie.remove("correo", { path: "/" });
    cookie.remove("cargo", { path: "/" });
    cookie.remove("tipoUsuario", { path: "/" });

    window.location = "/#/login";
  };
  return (
    <button className="btn btn-secondary" onClick={cerrarSecion}>
      Cerrar Sesion
    </button>
  );
}
export default CerrarSesion;
