import React, { useCallback, useEffect, useMemo, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import axios from "axios";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";

const InfoPlantillasCorreo = () => {
  const cookie = new Cookies();
  const postData = (e) => {
    window.location.href = "/#/CrearPrametros";
  };
  const [list1, setList1] = useState([]);
  const urlendpoint =
    "https://middlewarebackprd.azurewebsites.net/api/v1/ObtenerPlantillasCorreo";
  useEffect(() => {
    axios({
      auth: {
        username: "admin.icontec@icontec.org",
        password: "quAngEraMuSTerGerEDE",
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      method: "get",

      url: urlendpoint,
    })
      .then((Response) => {
        setList1(Response.data);
        console.log(Response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [setList1]);

  const [tableData, setTableData] = useState(() => list1);
  const [validationErrors, setValidationErrors] = useState({});

  const handleSaveRow = async ({ exitEditingMode, row, values }) => {
    axios({
      method: "put",
      auth: {
        username: "admin.icontec@icontec.org",
        password: "quAngEraMuSTerGerEDE",
      },
      url:
        "https://middlewarebackprd.azurewebsites.net/api/v1/ActualizaPlantilla/" +
        values.id,
      data: values,
    })
      .then((Response) => {
        console.log(Response.data);
        alert("Actualizado con exito!");
        window.location.reload("/");
      })
      .catch((error) => {
        console.log(error);
        alert("Error al Actualizar" + error);
      });

    //send/receive api updates here
    setTableData([...tableData]);
    exitEditingMode(); //required to exit editing mode
    console.log("post data ");
    console.log(values.id);
  };

  const handleDelete = useCallback(
    (row) => {
      axios({
        method: "delete",
        auth: {
          username: "admin.icontec@icontec.org",
          password: "quAngEraMuSTerGerEDE",
        },
        url:
          "https://middlewarebackprd.azurewebsites.net/api/v1/DeleteModulo?Id=" +
          row.getValue("id"),
      })
        .then((Response) => {
          Swal.fire({
            title: Response.data,
            text: "Eliminado",
            icon: "success",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              console.log(Response.data);

              window.location.reload("/");
            } else {
              Swal.fire("Your imaginary file is safe!");
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "Error!",
            text: "verifique los campos",
            icon: "error",
            timer: 4000,
          });
        });

      tableData.splice(row.index, 1);
      setTableData([...tableData]);
    },
    [tableData]
  );
  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  const handleDeleteRow = useCallback();

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "id",
        enableColumnOrdering: false,
        enableEditing: true,
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: "legado",
        header: "Legado",
        enableColumnOrdering: false,
        enableEditing: true,
        enableSorting: false,
        size: 80,
      },

      {
        accessorKey: "imagen",
        header: "Imagen",
        enableColumnOrdering: false,
        enableEditing: true,
        enableSorting: false,
        size: 80,
      },
    ],
    []
  );

  return (
    <div>
      <div className="test">
        <button
          className="btn btn-primary btn-lg"
          color="primary"
          onClick={postData}
        >
          Nuevo
        </button>
      </div>
      <MaterialReactTable
        displayColumnDefOptions={{
          "mrt-row-actions": {
            muiTableHeadCellProps: {
              align: "center",
            },
            size: 120,
          },
        }}
        columns={columns}
        data={list1}
        editingMode="modal"
        enableColumnOrdering
        enableEditing
        onEditingRowSave={handleSaveRow}
        onEditingRowCancel={handleCancelRowEdits}
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: "flex", gap: "1rem" }}>
            <Tooltip arrow placement="left" title="Edit">
              <IconButton onClick={() => table.setEditingRow(row)}>
                <Edit />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        renderDetailPanel={({ row }) => (
          <Box
            sx={{
              display: "grid",
              margin: "auto",
              gridTemplateColumns: "1fr 1fr",
              width: "100%",
            }}
          >
            <Typography>
              <b>Mensaje:</b> {row.original.mensaje}
            </Typography>
          </Box>
        )}
      />
    </div>
  );
};

export default InfoPlantillasCorreo;
