import Cookies from "universal-cookie";

// ----------------------------------------------------------------------
const cookie = new Cookies();
const account = {
  displayName: cookie.get("nombre"),
  email: cookie.get("correo"),
  photoURL: '/assets/images/avatars/avatar_default.jpg',
};

export default account;
