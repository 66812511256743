import { Grid, Paper, Button, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Form, Field } from "react-final-form";
import { TextField, Select } from "final-form-material-ui";
import Swal from "sweetalert2";
import axios from "axios";

function CreaCopias() {
  function redirige() {
    window.location.href = "/#/InfoCopias";
  }
  const onSubmit = async (values) => {
    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    await sleep(300);

    axios({
      auth: {
        username: "admin.icontec@icontec.org",
        password: "quAngEraMuSTerGerEDE",
      },
      method: "post",
      url: "https://middlewarebackprd.azurewebsites.net/api/v1/CreaCopiasEmail",
      data: {
        filial: values.filial,
        envio: values.envio,
        cargoEnvio: values.cargoEnvio,
        correoEnvio: values.correoCopia,
        copia: values.copia,
        cargoCopia: values.cargoCopia,
        correoCopia: values.correoCopia,
      },
    })
      .then((Response) => {
        Swal.fire({
          title: Response.data,
          icon: "success",
          type: "success",
        }).then((window.location.href = "/#/InfoCopias"));
      })

      .catch((error) => {
        Swal.fire({
          title: error,
          icon: "error",
          text: "Revise los campos e intente de nuevo!",
          type: "error",
        }).then(redirige);
      });
  };
  const validate = (values) => {
    const errors = {};
    if (!values.filial) {
      errors.filial = "Required";
    }

    if (!values.envio) {
      errors.envio = "Required";
    }

    if (!values.cargoEnvio) {
      errors.cargoEnvio = "Required";
    }
    if (!values.correoEnvio) {
      errors.correoEnvio = "Required";
    }

    console.log(errors);
    return errors;
  };

  return (
    <div>
      <div style={{ padding: 16, margin: "auto", maxWidth: 800 }}>
        <font color="">
          <h2>CREAR MODULO</h2>{" "}
        </font>
        <Form
          onSubmit={onSubmit}
          initialValues={{}}
          validate={validate}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Paper style={{ padding: 16 }}>
                <Grid container alignItems="flex-start" spacing={2}>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="filial"
                      component={TextField}
                      type="text"
                      label="Nombre de Filial"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="envio"
                      component={TextField}
                      type="text"
                      label="Usuario Principal "
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      required
                      name="cargoEnvio"
                      component={TextField}
                      type="text"
                      label="Cargo Usuario Principal"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      required
                      name="correoEnvio"
                      component={TextField}
                      type="text"
                      label="Correo Usuario Principal:"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      required
                      name="copia"
                      component={TextField}
                      type="text"
                      label="Nombre Usuario Opcional:"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="cargoCopia"
                      component={TextField}
                      type="text"
                      label="Cargo Usuario Opcional:"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="correoCopia"
                      component={TextField}
                      type="text"
                      label="Correo Usuario Opcional:"
                    />
                  </Grid>

                  <hr />
                  <Grid item style={{ marginTop: 16 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={submitting}
                      label="siguiente"
                    >
                      CREAR
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </form>
          )}
        />
      </div>
    </div>
  );
}

export default CreaCopias;
