import { Grid, Paper, Button, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Form, Field } from "react-final-form";
import { TextField, Select } from "final-form-material-ui";
import Swal from "sweetalert2";
import axios from "axios";

function CrearCampos() {
  function redirige() {
    window.location.reload("/");
  }
  const onSubmit = async (values) => {
    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    await sleep(300);

    axios({
      auth: {
        username: "admin.icontec@icontec.org",
        password: "quAngEraMuSTerGerEDE",
      },
      method: "post",
      url: "https://middlewarebackprd.azurewebsites.net/api/v1/CreaCampos",
      data: {
        nombreCampo: values.nombre,
        modulo: countryid,
      },
    })
      .then((Response) => {
        Swal.fire({
          title: Response.data,
          icon: "success",
          type: "success",
        }).then();
      })

      .catch((error) => {
        Swal.fire({
          title: error,
          icon: "error",
          text: "Revise los campos e intente de nuevo!",
          type: "error",
        }).then(redirige);
      });
  };
  const validate = (values) => {
    const errors = {};
    if (!values.nombre) {
      errors.nombre = "Required";
    }

    console.log(errors);
    return errors;
  };

  const [country, setCountry] = useState([]);
  const [countryid, setCountryid] = useState("");

  useEffect(() => {
    const getcountry = async () => {
      try {
        // Define your username and password
        const username = "admin.icontec@icontec.org";
        const password = "quAngEraMuSTerGerEDE";

        // Create a Basic Authentication header by encoding the credentials
        const basicAuth = "Basic " + btoa(username + ":" + password);

        const headers = {
          Authorization: basicAuth,
          "Content-Type": "application/json", // You can adjust this based on your API's requirements
        };

        const requestOptions = {
          method: "GET",
          headers: headers,
        };

        const rescountry = await fetch(
          "https://middlewarebackprd.azurewebsites.net/api/v1/ConsultaModulos",
          requestOptions
        );

        if (rescountry.ok) {
          const rescon = await rescountry.json();
          setCountry(rescon);
        } else {
          console.error("Error fetching data:", rescountry.status);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    getcountry();
  }, []);

  const handlecountry = (event) => {
    const getcountryid = event.target.value;
    setCountryid(getcountryid);
  };

  return (
    <div>
      <div style={{ padding: 16, margin: "auto", maxWidth: 800 }}>
        <font color="">
          <h2>CREAR CAMPOS</h2>
        </font>
        <Form
          onSubmit={onSubmit}
          initialValues={{}}
          validate={validate}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Paper style={{ padding: 16 }}>
                <Grid container alignItems="flex-start" spacing={2}>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="nombre"
                      component={TextField}
                      type="text"
                      label="Nombre"
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <select
                      className="btn btn-secondary btn-user btn-block text-left"
                      onChange={(e) => handlecountry(e)}
                    >
                      <option value="0">Seleccione el modulo </option>
                      {country.map((getcon, index) => (
                        <option key={index} value={getcon.modulo}>
                          {getcon.modulo}{" "}
                        </option>
                      ))}
                    </select>
                  </Grid>

                  <hr />
                  <Grid item style={{ marginTop: 16 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={submitting}
                      label="siguiente"
                    >
                      CREAR
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </form>
          )}
        />
      </div>
    </div>
  );
}

export default CrearCampos;
